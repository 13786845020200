import styled from "styled-components";

export const GestaoConveniadosContainer = styled.div`
  max-width: 1440px;
  padding: 1rem;
  margin: auto;
`;

export const BasicCardWrapper = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  width: 100%;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 490px) {
    grid-template-columns: 1fr;
  }
`;
