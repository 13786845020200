import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

interface CommonProps {
  open: boolean;
}

export const HeaderContainer = styled.div<CommonProps>`
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 1.25rem;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  min-height: 60px;
  gap: 36px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Logo = styled(Link)`
  display: flex;
  font-weight: bold;
  text-decoration: none;
  justify-content: center;
  width: 180px;
  align-items: center;
  height: 60px;
  z-index: 2;
  background-color: ${(props) => props.theme.white};

  @media screen and (max-width: 900px) {
    position: absolute;
    top: 0;
  }

  img {
    width: 118px;
    height: 50px;
  }
`;

export const Nav = styled.nav<CommonProps>`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media screen and (max-width: 900px) {
    max-height: ${(props) => (props.open ? "fit-content" : "0px")};
    opacity: ${(props) => (props.open ? "1" : "0")};
    flex-direction: column;

    width: 100%;
    margin-top: ${(props) => (props.open ? "6rem" : "-240px")};
    transition: margin-top 0.3s ease-in;
    margin-bottom: 32px;
  }
`;

export const CustomButton = styled.button<CommonProps>`
  margin-top: ${(props) => (props.open ? "0rem" : "-1000px")};

  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
  color: ${(props) => props.theme["purple-400"]};
  transition: color 0.3s ease-in-out;
  border: none;
  background-color: ${(props) => props.theme.white};

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme["gray-300"]};
  }

  @media screen and (max-width: 900px) {
    transition: opacity 0.5s ease-in;
    opacity: ${(props) => (props.open ? "1" : "0")};
  }
`;

export const NavItem = styled(Link)<CommonProps>`
  margin-top: ${(props) => (props.open ? "0rem" : "-1000px")};

  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
  color: ${(props) => props.theme["purple-400"]};
  transition: color 0.3s ease-in-out;

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme["gray-300"]};
  }

  @media screen and (max-width: 900px) {
    transition: opacity 0.5s ease-in;
    opacity: ${(props) => (props.open ? "1" : "0")};
  }
`;

export const Hamburger = styled.div<CommonProps>`
  display: none;
  cursor: pointer;
  position: absolute;
  border-radius: 3px;
  right: 14px;
  top: 28px;
  z-index: 1001;
  height: 2px;
  width: 24px;
  background-color: ${(props) =>
    props.open ? "rgba(0,0,0,0)" : props.theme["purple-400"]};

  @media screen and (max-width: 900px) {
    display: block;
    transition: transform 0.25s ease-in-out;
    transform: ${(props) => (props.open ? "rotate(90deg)" : "none")};
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${(props) => props.theme["purple-400"]};
    border-radius: 3px;
    transition: transform 0.5s ease-in-out, top 0.5s ease-in-out;
  }
  &::before {
    top: ${(props) => (props.open ? "0px" : "8px")};
    transform: ${(props) => (props.open ? "rotate(45deg)" : "none")};
  }

  &::after {
    top: ${(props) => (props.open ? "0px" : "-8px")};
    transform: ${(props) => (props.open ? "rotate(-45deg)" : "none")};
  }
`;
