import styled from "styled-components";

interface Props {
  image: string;
  image2: string;
}

export const PlaceHolderContainer = styled.div<Props>`
  justify-self: center;
  cursor: pointer;
  color: ${(props) => props.theme["gray-400"]};

  background-color: ${(props) => props.theme["gray-100"]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 4rem;
  flex-wrap: wrap;
  width: 160px;
  height: 458px;

  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    background-image: url(${(props) => props.image2});
    max-width: 458px;
    width: 100%;
    min-height: 120px;
    height: auto;
  }
`;
