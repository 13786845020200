import { LoadingContainer, LoadingPulse } from "./styles";

interface LoadingProps {
  isLoading: boolean;
}

export const Loading = ({ isLoading }: LoadingProps) => {
  return (
    <LoadingContainer isLoading={isLoading}>
      <LoadingPulse />
      <br />
      <p>Carregando...</p>
    </LoadingContainer>
  );
};
