import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState, useCallback } from "react";
import { useParams } from "react-router";
import { IStore } from "../../../model/store";
import { staticCategories } from "../../../assets/content";
import conveniadoService from "../../../services/conveniadoService";
import textUtils from "../../../utils/textUtils";
import { CategoriesHeader } from "../components/categoriesHeader";
import { CategoriesStickyCard } from "../components/categoriesStickyCards";
import { StoreCard } from "../components/storeCard";
import { StoreCardWrapper } from "./styles";

export const CategoriesPage = () => {
  const GET_CONVENIADO_ERROR_MESSAGE =
    "Ocorreu um erro ao obter os conveniados. Por favor, tente novamente.";
  const params = useParams();
  const toast = useRef<Toast>(null);
  const [conveniados, setConveniados] = useState<Array<IStore>>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [size, setSize] = useState(20);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setSize(event.rows);
    setPage(event.page);
    setFirst(event.first);
    window.scrollTo(0, 350);
  };

  // Função de timeout para obter conveniados
  const delayedGetConveniados = useCallback((size: number) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      getConveniados(size);
    }, 300); // 300ms de atraso
    setTimeoutId(newTimeoutId);
  }, [params.category, size, page, timeoutId]);

  useEffect(() => {
    delayedGetConveniados(size);
  }, [params.category, size, page, delayedGetConveniados]);

  async function getConveniados(size: number) {
    setLoading(true);
    try {
      const filter = findModalidadeIdBySlug();
      const requestParams = { filter, page, size };
      const response = await conveniadoService.getConveniadosByModalidadeId(
        requestParams
      );

      setTotalRecords(response.data.totalElements);
      setConveniados(response.data.content);
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "Erro",
        detail: GET_CONVENIADO_ERROR_MESSAGE,
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  }

  function findModalidadeIdBySlug() {
    const modalidade = staticCategories.find(
      (modalidade) =>
        textUtils.routeFormater(modalidade.name) === params.category
    );
    return modalidade?.id.toString() ?? "";
  }

  return (
    <>
      <Toast ref={toast} />
      <CategoriesHeader />
      <CategoriesStickyCard />
      {loading && <div className="loading">Carregando...</div>}
      <StoreCardWrapper>
        {Array.isArray(conveniados) &&
          conveniados.map((store: IStore) => {
            const {
              nome,
              modalidade,
              endereco,
              descricao,
              instagram,
              site,
              imagem,
            } = store;
            return (
              <StoreCard
                key={store.id}
                endereco={endereco}
                modalidade={modalidade}
                descricao={descricao}
                imagem={imagem}
                instagram={instagram}
                nome={nome}
                site={site}
                id={store.id}
              />
            );
          })}
      </StoreCardWrapper>
      <Paginator
        rows={size}
        first={first}
        totalRecords={totalRecords}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
    </>
  );
};
