import { Route, Routes } from "react-router";
import { PageNotFound } from "../components/pageNotFound";

import { CategoriesHealthPage } from "../pages/categories-health/containers";
import { CategoriesPage } from "../pages/categories/containers";
import { ListagemDescontos } from "../pages/descontos";
import { Home } from "../pages/home/container";
import { Login } from "../pages/login";

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<PageNotFound isPageNotFound />} />
      <Route path="/home" element={<Home />} />
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/parceiros" element={<CategoriesPage />} />
      <Route
        path="/parceiros/categoria/:category"
        element={<CategoriesPage />}
      />
      <Route path="/cartao-saude" element={<CategoriesHealthPage />} />
      <Route
        path="/cartao-saude/categoria/:category"
        element={<CategoriesHealthPage />}
      />
      <Route path="/descontos" element={<ListagemDescontos />} />
    </Routes>
  );
};
