import styled from "styled-components";

export const TextAreaContainer = styled.div`
  textarea {
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #b0b0b0;
    color: #464646;
    resize: none;
    height: 13.75rem;

    &:focus {
      outline: none;
      border: 1px solid ${(props) => props.theme["purple-400"]};
      background-color: ${(props) => props.theme["gray-50"]};
    }
  }

  p {
    font-size: 0.875rem;
    margin-bottom: 4px;
    width: fit-content;
    border-bottom: 2px solid ${(props) => props.theme["purple-300"]};
  }
`;
