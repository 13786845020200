import textUtils from "../../../../utils/textUtils";
import { categoriesHeaderContent } from "./content";
import { CategoriesHeaderContainer } from "./styles";

export const CategoriesHeader = () => {
  const { title, subtitle } = categoriesHeaderContent;

  return (
    <CategoriesHeaderContainer>
      <h2>{textUtils.handleTextHigliht(title)}</h2>
      <p>{subtitle}</p>
    </CategoriesHeaderContainer>
  );
};
