import { AxiosResponse } from "axios";
import { ILoginUsuario, IUsuario } from "../interfaces/model/iUsuario";
import { api } from "./api";
import { IToken } from "../interfaces/model/iToken";

const url: string = "usuarios/";

const getUsuarios = (): Promise<AxiosResponse<IUsuario[]>> => {
  return api.get(url);
};

const getUsuarioId = (id: number): Promise<AxiosResponse<IUsuario>> => {
  return api.get(url + id);
};

const verifyAuth = (): Promise<AxiosResponse<string>> => {
  return api.get(url + "verify");
};

const login = (usuario: ILoginUsuario): Promise<AxiosResponse<IToken>> => {
  return api.post("/auth/login", usuario);
};

const usuarioService = {
  getUsuarios,
  getUsuarioId,
  verifyAuth,
  login,
};

export default usuarioService;
