import styled from "styled-components";

export const InputContainer = styled.div`
  span {
    position: relative;
  }

  div {
    position: absolute;
    left: 12px;
    bottom: -7px;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    padding-left: 42px;
    border-radius: 4px;
    border: 1px solid #b0b0b0;
    color: #464646;

    &:focus {
      outline: none;
      border: 1px solid ${(props) => props.theme["purple-300"]};
      background-color: ${(props) => props.theme["gray-50"]};
    }
  }

  p {
    font-size: 0.875rem;
    margin-bottom: 4px;
    width: fit-content;
    border-bottom: 2px solid ${(props) => props.theme["purple-300"]};
  }
`;
