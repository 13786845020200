import textUtils from "../../../../../utils/textUtils";
import { FeedbackCard } from "../components/card/feedbackCard";
import { feedbackContent } from "./content";
import { FeedbackCardWrapper } from "./styles";

function customStyleForInitialAndLastCard(
  feedbackLength: number,
  index: number
) {
  if (index === 0) {
    return { marginLeft: "1rem" };
  } else if (feedbackLength - 1 === index) {
    return { marginRight: "1rem" };
  }
}

export const Feedbacks = () => {
  return (
    <div style={{ padding: "0 1rem" }}>
      <div style={{ maxWidth: "1440px", margin: "auto" }}>
        <h1 style={{ maxWidth: "600px" }}>
          {textUtils.handleTextHigliht(feedbackContent.title)}
        </h1>
      </div>
      <FeedbackCardWrapper>
        {feedbackContent.feedbacks.map((feedback, index) => {
          const { title, comment, picture, rate, subtitle } = feedback;
          return (
            <FeedbackCard
              title={title}
              comment={comment}
              picture={picture}
              rate={rate}
              subtitle={subtitle}
              key={title + index}
              style={customStyleForInitialAndLastCard(
                feedbackContent.feedbacks.length,
                index
              )}
            />
          );
        })}
      </FeedbackCardWrapper>
    </div>
  );
};
