import { CardDecontos } from "../cardDescontos";
import { InitContainers } from "../initContainers";
import { Feedbacks } from "../sections/feedbacks/container/feedbacks";
import { Partners } from "../sections/partners/container/partners";
import { VideoContainer } from "../sections/video";

export const Home = () => {
  return (
    <>
      <InitContainers />
      <CardDecontos />
      <VideoContainer />
      <p style={{ marginBottom: "60px" }}> </p>
      <Partners />
      <p style={{ marginBottom: "60px" }}> </p>
      <Feedbacks />
    </>
  );
};
