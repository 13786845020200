import styled from "styled-components";

interface Props {
  height: string;
}

export const TextAreaContainer = styled.div<Props>`
  height: fit-content;
  position: relative;

  label {
    width: fit-content;
    font-size: 1.25rem;
    font-weight: 700;
    border-bottom: 3px solid ${(props) => props.theme["purple-500"]};
  }

  p {
    transition: all 0.25s ease-in-out;
    font-weight: 600;
    font-size: 0.875rem;
    position: absolute;
    right: 0;
    top: 2px;
  }

  textarea {
    margin-top: 12px;
    outline-color: ${(props) => props.theme["purple-500"]};
    width: 100%;
    border-radius: 6px;
    height: ${(props) => props.height};
    border: 1px solid ${(props) => props.theme["gray-50"]};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    resize: none;

    padding: 14px 12px 0px 10px;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 100px;
      background: ${(props) => props.theme["gray-50"]};
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background: ${(props) => props.theme["gray-100"]};
    }
  }
`;
