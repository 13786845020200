import { useContext, useEffect } from "react";
import { BiUser } from "react-icons/bi";
import { TbDoorExit } from "react-icons/tb";
import { useNavigate } from "react-router";
import { PurpleButton } from "../../components/buttons/purpleButton";
import { AuthContext } from "../../context/AuthContext";
import usuarioService from "../../services/usuarioService";
import { scrollUp } from "../../utils/pageUtils";
import { Container, ExitButton, MenuAdmin, MenuButton } from "./styles";

export const Admin = () => {
  const { signOut, user, token } = useContext(AuthContext);
  const navigate = useNavigate();

  async function verifyToken() {
    await usuarioService
      .verifyAuth()
      .then((response) => {
        if (response.data !== "autenticado") {
          signOut();
        }
      })
      .catch(() => {
        signOut();
      });
  }

  useEffect(() => {
    scrollUp();
    if (token) {
      setTimeout(() => {
        verifyToken();
      }, 100);
    }
  }, []);

  return (
    <Container>
      <MenuAdmin>
        <div>
          <BiUser
            style={{
              borderRadius: "50%",
              background: "#222226",
              padding: "0.5rem",
            }}
            size={54}
          />
          <section>
            <p>Bem Vindo!</p>
            <h1>{user?.nome}</h1>
          </section>
        </div>
        <ExitButton onClick={signOut}>
          Sair
          <TbDoorExit size={28} />
        </ExitButton>
      </MenuAdmin>
      <MenuButton>
        <PurpleButton
          style={{ width: "100%" }}
          title="Adicionar Conveniado"
          onClick={() => navigate("/conveniado/adicionar")}
        />
        <PurpleButton
          style={{ width: "100%" }}
          title="Gestão de conveniados"
          onClick={() => navigate("/conveniado/gestao")}
        />
        <PurpleButton
          style={{ width: "100%" }}
          title="Adicionar Conveniado Saude"
          onClick={() => navigate("/conveniado-saude/adicionar")}
        />
        <PurpleButton
          style={{ width: "100%" }}
          title="Gestão de conveniados Saude"
          onClick={() => navigate("/conveniado/gestao-saude")}
        />
        <PurpleButton
          style={{ width: "100%" }}
          title="Adicionar Desconto"
          onClick={() => navigate("/descontos/adicionar")}
        />
        <PurpleButton
          style={{ width: "100%" }}
          title="Gestão de Descontos"
          onClick={() => navigate("/descontos/gestao")}
        />

        <PurpleButton
          style={{ width: "100%" }}
          title="Atualizar Ads"
          onClick={() => navigate("/ads/post")}
        />
      </MenuButton>
    </Container>
  );
};
