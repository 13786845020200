import { Galleria } from "primereact/galleria";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import banner3 from "../../../assets/images/home/banners/MULTISUOOUROCARd2.png";
import banner4 from "../../../assets/images/home/banners/Multiusocard1.png";
import { AdsPlaceHolder } from "../../../components/adsPlaceHolder";
import { Carrousel } from "../../../components/carrousel";
import { Loading } from "../../../components/loading";
import publicidadesService from "../../../services/publicidadesService";
import { findAdsByName, scrollUp } from "../../../utils/pageUtils";
import { CaroselWrapper, HomeContainer, PositionAds } from "./styles";

export const InitContainers = () => {
  const galleria = useRef<Galleria>(null);
  const galleria2 = useRef<Galleria>(null);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [carrouselNews, setCarrouselNews] = useState<INoticia[] | null>(
    mockNoticias
  );
  const [home, setHome] = useState<IPublicidades | undefined>();

  const [selectedImage, setSelectedImage] = useState<Array<string>>([]);
  const itemTemplate = (item: any) => {
    return <img src={item} style={{ width: "100%", display: "block" }} />;
  };

  useEffect(() => {
    scrollUp();
    getAds();
  }, []);

  async function getAds() {
    publicidadesService
      .getAllAds()
      .then((res) => {
        res && setHome(findAdsByName({ nome: "Home 1", array: res.data }));
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  /*NOTA DE REPUDIO: ESSE TRABALHO TA UMA LIXEIRA, PUTA QUE PARIU, SÓ TEM GAMBIARRA NESSA MERDA
    TEM USESTATE DE LOADING QUE NUNCA É USADO MOLEQUE, ESSE NEGÓCIO TA UM NOJO.
    FODA-SE, ANGULAR PÕE REACT PRA MAMAR E EU COMI O CU DE QUEM TA LENDO.
  */
  const carrouselImageClick = (image: any) => {
    if (image.imageBanner) {
      if (image.title.includes("Cartão Afesp MultiusoCard Três em Um.")) {
        galleria2.current?.show();
      } else {
        setSelectedImage(image.imageBanner);
        galleria.current?.show();
      }
    } else if (image.routerLink) {
      if (!image.routerLink.includes("afesptv")) navigate(image.routerLink);
      else window.open(image.routerLink);
    }
  };

  return (
    <HomeContainer>
      <Galleria
        ref={galleria}
        value={[selectedImage]}
        numVisible={1}
        style={{
          maxWidth: "920px",
          width: "100%",
          padding: "1rem",
          margin: "auto",
        }}
        circular
        fullScreen
        showThumbnails={false}
        item={itemTemplate}
      />
      <Galleria
        ref={galleria2}
        value={[banner4, banner3]}
        numVisible={1}
        style={{
          maxWidth: "920px",
          width: "100%",
          padding: "1rem",
          margin: "auto",
        }}
        fullScreen
        showItemNavigators
        showThumbnails={false}
        item={itemTemplate}
      />
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <>
          <CaroselWrapper>
            {carrouselNews && galleria.current && (
              <Carrousel
                newsList={carrouselNews}
                onImageClick={carrouselImageClick}
              />
            )}
          </CaroselWrapper>

          {home && (
            <PositionAds>
              <AdsPlaceHolder
                key={home.id}
                image={home.imagemWeb + ""}
                image2={home.imagemMobile + ""}
                link={home.linkUrl + ""}
              />
            </PositionAds>
          )}
        </>
      )}
    </HomeContainer>
  );
};

export const mockPublicidades: IPublicidades[] = [
  {
    id: 1,
    nome: "Publicidade 1",
    imagemWeb:
      "https://i.postimg.cc/d33gj9tM/Publicidade-do-lado-do-carrossel.png",
    imagemMobile:
      "https://i.postimg.cc/d33gj9tM/Publicidade-do-lado-do-carrossel.png",
    recomendadoWeb: "Recomendado para web 1",
    recomendadoMobile: "Recomendado para mobile 1",
    linkUrl: "https://www.example.com/publicidade1",
  },
  {
    id: 2,
    nome: "Publicidade 2",
    imagemWeb:
      "https://i.postimg.cc/d33gj9tM/Publicidade-do-lado-do-carrossel.png",
    imagemMobile:
      "https://i.postimg.cc/d33gj9tM/Publicidade-do-lado-do-carrossel.png",
    recomendadoWeb: "Recomendado para web 2",
    recomendadoMobile: "Recomendado para mobile 2",
    linkUrl: "https://www.example.com/publicidade2",
  },
  // ... mais publicidades se necessário
];

export const mockNoticias: INoticia[] = [
  {
    id: 1,
    categoria: {
      id: 1,
      nome: " Categoria 2",
    },
    titulo: " ",
    imagem: "https://i.postimg.cc/RhWbHVX4/1-Cart-o-SAUDE.png",
    video: "https://www.example.com/video1.mp4",
    data: "2023-11-03",
    autor: "Autor da Notícia 2",
    cidade: "Cidade da Notícia 1",
    slug: "noticia-1",
  },
  {
    id: 2,
    categoria: {
      id: 2,
      nome: "Categoria 2",
    },
    titulo: " ",
    imagem: "https://i.postimg.cc/FKGPCJ6h/2-CONSIGNADO.png",
    video: "https://www.example.com/video2.mp4",
    data: "2023-11-03",
    autor: "Autor da Notícia 2",
    cidade: "Cidade da Notícia 2",
    slug: "noticia-2",
  },
  {
    id: 3,
    categoria: {
      id: 2,
      nome: "Categoria 2",
    },
    titulo: " ",
    imagem: "https://i.postimg.cc/tTTBK40W/3-cart-o-desconto.png",
    video: "https://www.example.com/video2.mp4",
    data: "2023-11-03",
    autor: "Autor da Notícia 2",
    cidade: "Cidade da Notícia 2",
    slug: "noticia-2",
  },
];

export interface IPublicidades {
  id?: number;
  nome: string;
  imagemWeb?: string;
  recomendadoWeb: string;
  imagemMobile?: string;
  recomendadoMobile: string;
  linkUrl: string;
}

export interface INoticia {
  id: number;
  categoria: ICategoria;
  titulo: string;
  imagem: string;
  video: string;
  data: string;
  autor: string;
  cidade: string;
  slug?: string;
}

export interface ICategoria {
  id: number;
  nome: string;
  imagem?: string;
}
