import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { PurpleButton } from "../../../components/buttons/purpleButton";
import { DescontoCard } from "../../../components/descontoCard";
import { IDesconto } from "../../../model/descontos";
import descontosService from "../../../services/descontosService";
import { goToPath } from "../../../utils/pageUtils";
import textUtils from "../../../utils/textUtils";
import {
  BasicCardWrapper,
  Container,
  GestaoConveniadosContainer,
} from "./style";

export const CardDecontos = () => {
  const GET_DESCONTOS_ERROR_MESSAGE =
    "Ocorreu um erro ao obter os descontos. Por favor, tente novamente.";

  const toast = useRef<Toast>(null);
  const [descontos, setDescontos] = useState<Array<IDesconto>>();

  useEffect(() => {
    descontosService
      .getDescontosAleatorios()
      .then((res) => {
        setDescontos(res.data);
      })
      .catch((err) => {
        console.error("Erro ao obter descontos:", err);
        toastMessage("error", GET_DESCONTOS_ERROR_MESSAGE);
      });
  }, []);

  function toastMessage(type: "error" | "success", detail: string) {
    toast.current?.show({
      severity: type,
      summary: type === "error" ? "Erro" : "Successo",
      detail,
      life: 3000,
    });
  }

  return (
    <>
      {Array.isArray(descontos) && descontos.length > 0 ? (
        <>
          <Container>
            <h1 style={{ maxWidth: "700px" }}>
              {textUtils.handleTextHigliht(
                "Confira nossos %Descontos Exclusivos%"
              )}
            </h1>
            <PurpleButton
              title="Ver mais"
              onClick={() => goToPath("/descontos")}
            />
          </Container>
          <GestaoConveniadosContainer>
            <BasicCardWrapper>
              {descontos.map((desconto) => (
                <DescontoCard
                  key={desconto.id}
                  desconto={desconto.desconto}
                  descricao={desconto.descricao}
                  endereco={desconto.endereco}
                  imagem={desconto.imagem}
                  nome={desconto.nome}
                />
              ))}
            </BasicCardWrapper>
          </GestaoConveniadosContainer>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};
