import { ConfirmDialog } from "primereact/confirmdialog";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";

import { DescontoCard } from "../../components/descontoCard";
import { IDesconto } from "../../model/descontos";
import descontosService from "../../services/descontosService";
import { BasicCardWrapper, GestaoConveniadosContainer } from "./styles";

export const ListagemDescontos = () => {
  const GET_DESCONTOS_ERROR_MESSAGE =
    "Ocorreu um erro ao obter os descontos. Por favor, tente novamente.";

  const toast = useRef<Toast>(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [size, setSize] = useState(25);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [descontos, setDescontos] = useState<Array<IDesconto>>();

  useEffect(() => {
    getDescontos(size);
  }, [size, page]);

  async function getDescontos(size: number) {
    try {
      const requestParams = { filter: "", page, size };
      const response = await descontosService.getDescontos(requestParams);

      setTotalRecords(response.data.totalElements);
      setDescontos(response.data.content);
    } catch (error) {
      console.error("Erro ao obter descontos:", error);
      toastMessage("error", GET_DESCONTOS_ERROR_MESSAGE);
    }
  }

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setSize(event.rows);
    setPage(event.page);
    setFirst(event.first);
    window.scrollTo(0, 350);
  };

  function toastMessage(type: "error" | "success", detail: string) {
    toast.current?.show({
      severity: type,
      summary: type === "error" ? "Erro" : "Successo",
      detail,
      life: 3000,
    });
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />

      <GestaoConveniadosContainer>
        <h2 style={{ margin: "1.5rem 0" }}>Descontos</h2>

        <BasicCardWrapper>
          {Array.isArray(descontos) && descontos.length > 0 ? (
            descontos.map((desconto) => {
              return (
                <DescontoCard
                  key={desconto.id}
                  desconto={desconto.desconto}
                  descricao={desconto.descricao}
                  endereco={desconto.endereco}
                  imagem={desconto.imagem}
                  nome={desconto.nome}
                ></DescontoCard>
              );
            })
          ) : (
            <p
              style={{
                position: "absolute",
                left: "50%",
                width: "100%",
                textAlign: "center",
                transform: "translateX(-50%)",
              }}
            >
              Nenhum resultado foi encontrado
            </p>
          )}
        </BasicCardWrapper>
        <Paginator
          rows={size}
          first={first}
          totalRecords={totalRecords}
          rowsPerPageOptions={[25, 50, 75, 100]}
          onPageChange={onPageChange}
          style={{ marginTop: "1rem" }}
        />
      </GestaoConveniadosContainer>
    </>
  );
};
