import styled from "styled-components";

export const CategoryCardContainer = styled.div`
  position: sticky;
  display: flex;
  max-width: 1440px;
  margin: auto;
  background-color: ${(props) => props.theme.white};
  z-index: 2;

  flex-direction: column;
  gap: 1rem;
  top: 0;
  padding: 0.5rem 1rem;

  strong {
    color: ${(props) => props.theme["purple-400"]};
  }

  p {
    font-size: 0.875rem;
  }
`;

export const CategoryCardWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  position: sticky;
  top: 0;
  padding: 4rem 0rem;
  padding-bottom: 1rem;
  align-items: flex-end;
`;
