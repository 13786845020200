import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { BasicCard } from "../../../../components/basicCard";
import { IModalidade } from "../../../../model/modalidade";
import { IStore } from "../../../../model/store";
import conveniadoService from "../../../../services/conveniadoService";
import modalidadeService from "../../../../services/modalidadeService";
import { BasicCardWrapper, GestaoConveniadosContainer } from "./styles";

export const GestaoConveniados = () => {
  const GET_CONVENIADO_ERROR_MESSAGE =
    "Ocorreu um erro ao obter os conveniados. Por favor, tente novamente.";

  const params = useParams();
  const toast = useRef<Toast>(null);
  const [conveniados, setConveniados] = useState<Array<IStore>>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [size, setSize] = useState(25);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [modalidade, setModalidade] = useState<string>("");
  const [modalidadesOptions, setModalidadesOptions] = useState<
    Array<IModalidade>
  >([]);

  useEffect(() => getModaliadades(), []);
  useEffect(() => {
    getConveniados(size);
  }, [params.category, size, page, modalidade]);

  function getModaliadades() {
    try {
      modalidadeService.getModalidade().then((res) => {
        const md = res.data;
        md.unshift({ nome: "Todos", id: 0 });
        setModalidadesOptions(md);
      });
    } catch (e) {
      errorMessage("Falha ao obter modalidades.");
    }
  }

  function handleModalidadeChange(value: string) {
    setModalidade(value);
  }

  async function getConveniados(size: number) {
    try {
      const filter = modalidade.toString() === "0" ? "" : modalidade;
      const requestParams = { filter, page, size };
      const response = await conveniadoService.getConveniadosByModalidadeId(
        requestParams
      );

      setTotalRecords(response.data.totalElements);
      setConveniados(response.data.content);
    } catch (error) {
      console.error("Erro ao obter conveniados:", error);
      errorMessage(GET_CONVENIADO_ERROR_MESSAGE);
    }
  }

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setSize(event.rows);
    setPage(event.page);
    setFirst(event.first);
    window.scrollTo(0, 350);
  };

  async function onDelete(id: number) {
    const failMessage =
      "Ocorreu um erro ao deletar o conveniado. Por favor, tente novamente mais tarde.";
    try {
      const res = await conveniadoService.deleteConveniado(id);

      if (res.status === 200) {
        toast.current?.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Conveniado deletado com sucesso!",
          life: 3000,
        });
        getConveniados(size);
      } else {
        errorMessage(failMessage);
      }
    } catch (error) {
      console.error("Erro ao deletar conveniado:", error);
      errorMessage(failMessage);
    }
  }

  function errorMessage(detail: string) {
    toast.current?.show({
      severity: "error",
      summary: "Erro",
      detail,
      life: 3000,
    });
  }

  const confirmDelete = (id: number) => {
    confirmDialog({
      acceptLabel: "Confirmar",
      rejectLabel: "Cancelar",
      message: "Você tem certeza que deseja deletar esse parceiro?",
      header: "Atenção",
      icon: "pi pi-exclamation-triangle",
      accept: () => onDelete(id),
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />

      <GestaoConveniadosContainer>
        <h2 style={{ margin: "1.5rem 0" }}>Gestão de Conveniados</h2>
        <Dropdown
          value={modalidade}
          onChange={(e: DropdownChangeEvent) => handleModalidadeChange(e.value)}
          options={modalidadesOptions}
          optionLabel="nome"
          optionValue="id"
          placeholder="Selecione uma modalidade"
          className="w-full md:w-14rem"
        />

        <BasicCardWrapper>
          {Array.isArray(conveniados) &&
            conveniados.map((conveniado) => {
              const { id, nome, endereco, imagem } = conveniado;
              return (
                <BasicCard
                  image={imagem}
                  title={nome}
                  subtitle={endereco}
                  key={id}
                  onDelete={() => confirmDelete(id)}
                />
              );
            })}
        </BasicCardWrapper>
        <Paginator
          rows={size}
          first={first}
          totalRecords={totalRecords}
          rowsPerPageOptions={[25, 50, 75, 100]}
          onPageChange={onPageChange}
        />
      </GestaoConveniadosContainer>
    </>
  );
};
