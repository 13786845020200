import { Galleria } from "primereact/galleria";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import logo from "../../assets/images/navbar/Logo.png";
import comoAssociar from "../../assets/images/navbar/como-associar.jpeg";
import comoConveniar from "../../assets/images/navbar/como-conveniar.jpeg";
import oQueEAfesp from "../../assets/images/navbar/o-que-e-afesp.jpg";
import { AuthContext } from "../../context/AuthContext";
import { SubscribeDialogContext } from "../../context/SubscribeDialogContext";
import { PurpleButton } from "../buttons/purpleButton";
import { ContentNumberInit } from "../contentNumberInit";
import { SubscribeDialog } from "../subscribeDialog";
import {
  CustomButton,
  Hamburger,
  HeaderContainer,
  Logo,
  Nav,
  NavItem,
} from "./styles";

export const Navbar: React.FC = () => {
  const galleria = useRef<Galleria>(null);
  const [selectedImage, setSelectedImage] = useState("");

  const [isOpen, setIsOpen] = useState(window.innerWidth >= 900);
  const [auth, setAuth] = useState(false);

  const { token, user } = useContext(AuthContext);
  const { isSubscribeDialogOpen, openSubscribeDialog, closeSubscribeDialog } =
    useContext(SubscribeDialogContext);

  useEffect(() => {
    if (user !== null && token !== null) setAuth(true);
    else setAuth(false);
  }, [token, user]);

  useEffect(() => {
    updateIsOpen();
    window.addEventListener("resize", updateIsOpen);

    return () => window.removeEventListener("resize", updateIsOpen);
  }, []);

  function updateIsOpen() {
    setIsOpen(window.innerWidth >= 900);
  }

  const togleMenu = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const closeMenu = useCallback(() => {
    if (window.innerWidth < 900) setIsOpen(false);
  }, []);

  // function openDialog() {
  //   closeMenu();
  //   openSubscribeDialog();
  // }

  const itemTemplate = (item: any) => {
    return <img src={item} style={{ width: "100%", display: "block" }} />;
  };

  function openGalleria(image: string) {
    closeMenu();
    setSelectedImage(image);
    galleria.current?.show();
  }

  return (
    <>
      <ContentNumberInit />
      <HeaderContainer open={isOpen}>
        <Logo to={"/"} onClick={closeMenu}>
          <img src={logo} alt="Afesp Convênios" />
        </Logo>
        <Hamburger open={isOpen} onClick={togleMenu} />
        <Nav open={isOpen}>
          {auth && (
            <NavItem to={"/admin"} open={isOpen} onClick={closeMenu}>
              Administrador
            </NavItem>
          )}

          <CustomButton open={isOpen} onClick={() => openGalleria(oQueEAfesp)}>
            O que é Afesp?
          </CustomButton>

          <NavItem open={isOpen} to={"/parceiros"} onClick={closeMenu}>
            Cartão Afesp
          </NavItem>
          <NavItem open={isOpen} to={"/cartao-saude"} onClick={closeMenu}>
            Cartão Saude
          </NavItem>
          <NavItem open={isOpen} to={"/descontos"} onClick={closeMenu}>
            Descontos do Convênio
          </NavItem>

          {/* <PurpleButton title="PEÇA JÁ O SEU" onClick={openDialog} /> */}
          <PurpleButton
            title="Como associar?"
            onClick={() => openGalleria(comoAssociar)}
          />
          <PurpleButton
            title="Seja parceiro!"
            onClick={() => openGalleria(comoConveniar)}
          />
        </Nav>
        <Galleria
          ref={galleria}
          value={[selectedImage]}
          numVisible={1}
          style={{
            maxWidth: "920px",
            width: "100%",
            padding: "1rem",
            margin: "auto",
          }}
          circular
          fullScreen
          showThumbnails={false}
          item={itemTemplate}
        />
        <SubscribeDialog
          onHide={closeSubscribeDialog}
          visible={isSubscribeDialogOpen}
        />
      </HeaderContainer>
    </>
  );
};
