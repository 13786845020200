import { AxiosResponse } from "axios";
import { IPageable, IPageableParams } from "../interfaces/model/pageable";
import { IStore } from "../model/store";
import { api } from "./api";

const url: string = "conveniados";

const getConveniadosByModalidadeId = ({
  filter,
  page,
  size,
}: IPageableParams): Promise<AxiosResponse<IPageable<IStore>>> => {
  return api.get(`${url}/modalidade`, {
    params: { modalidadeId: filter, page, size },
  });
};

const getConveniadosByModalidadeSaudeId = ({
  filter,
  page,
  size,
}: IPageableParams): Promise<AxiosResponse<IPageable<IStore>>> => {
  return api.get(`${url}/modalidade-saude`, {
    params: { modalidadeId: filter, page, size },
  });
};

const saveConveniado = (
  store: Partial<IStore>
): Promise<AxiosResponse<IStore>> => {
  return api.post(url, store);
};

const deleteConveniado = (id: number): Promise<AxiosResponse<void>> => {
  return api.delete(`${url}/${id}`);
};

const getAllConveniados = ({
  filter,
  page,
  size,
}: IPageableParams): Promise<AxiosResponse<IPageable<IStore>>> => {
  return api.get(`${url}`, {
    params: { modalidadeId: filter, page, size },
  });
};

const conveniadoService = {
  getConveniadosByModalidadeId,
  getConveniadosByModalidadeSaudeId,
  getAllConveniados,
  saveConveniado,
  deleteConveniado,
};

export default conveniadoService;
