import styled from "styled-components";

export const Container = styled.main`
  position: relative;
  height: 89vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  margin: auto;
  color: ${(props) => props.theme["gray-100"]};
  padding: 1rem;
`;

export const MenuAdmin = styled.div`
  display: flex;
  width: 100%;
  padding: 1.5rem;
  border-radius: 10px;
  justify-content: space-between;
  background-color: ${(props) => props.theme["gray-900"]};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  section {
    display: flex;
    flex-direction: column;
  }

  p {
    margin-bottom: 5px;
  }

  /* @media (max-width: 768px) {
    width: 100%;

    p {
      margin-bottom: 5px;
    }
    h1 {
      font-size: 20px;
    }
  } */
`;

export const MenuButton = styled.div`
  display: grid;
  margin: 1rem;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 3rem 1rem;
  border-radius: 10px;
  background-color: ${(props) => props.theme["gray-900"]};
  gap: 1rem;
  justify-content: center;
`;

export const ExitButton = styled.button`
  display: flex;
  height: fit-content;

  padding: 4px 12px;
  gap: 24px;

  border-radius: 6px;
  border: none;

  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme["red-500"]};
  color: ${(props) => props.theme.white};
  transition: all 0.2s ease-in-out;

  font-weight: 700;
  font-size: 1.25rem;

  &:hover {
    background-color: ${(props) => props.theme["red-700"]};
  }
`;
