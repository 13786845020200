import { createContext, useMemo, useState } from "react";

interface ISubscribeDialogContext {
  openSubscribeDialog: VoidFunction;
  closeSubscribeDialog: VoidFunction;
  isSubscribeDialogOpen: boolean;
}

interface ISubscribeDialogProviderProps {
  children: React.ReactNode;
}

export const SubscribeDialogContext = createContext<ISubscribeDialogContext>({
  openSubscribeDialog: () => {},
  closeSubscribeDialog: () => {},
  isSubscribeDialogOpen: false,
});

export const SubscribeDialogProvider: React.FC<
  ISubscribeDialogProviderProps
> = ({ children }) => {
  const [isSubscribeDialogOpen, setIsSubscribeDialogOpen] = useState(false);

  function closeSubscribeDialog() {
    setIsSubscribeDialogOpen(false);
  }

  function openSubscribeDialog() {
    setIsSubscribeDialogOpen(true);
  }

  return useMemo(
    () => (
      <SubscribeDialogContext.Provider
        value={{
          isSubscribeDialogOpen,
          closeSubscribeDialog,
          openSubscribeDialog,
        }}
      >
        {children}
      </SubscribeDialogContext.Provider>
    ),
    [isSubscribeDialogOpen, children]
  );
};
