import styled from "styled-components";

export const GestaoConveniadosContainer = styled.div`
  max-width: 1440px;
  padding: 1rem;
  margin: auto;
`;

export const BasicCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
`;
