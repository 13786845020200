import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { HiTrash } from "react-icons/hi";
import { DescontoCard } from "../../../../components/descontoCard";
import { IDesconto } from "../../../../model/descontos";
import descontosService from "../../../../services/descontosService";
import { defaultTheme } from "../../../../styles/colors/default";
import { BasicCardWrapper, GestaoConveniadosContainer } from "./styles";

export const GestaoDescontos = () => {
  const GET_DESCONTOS_ERROR_MESSAGE =
    "Ocorreu um erro ao obter os descontos. Por favor, tente novamente.";

  const toast = useRef<Toast>(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [size, setSize] = useState(25);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(0);
  const [descontos, setDescontos] = useState<Array<IDesconto>>();

  useEffect(() => {
    getDescontos(size);
  }, [size, page]);

  async function getDescontos(size: number) {
    try {
      const requestParams = { filter: "", page, size };
      const response = await descontosService.getDescontos(requestParams);

      setTotalRecords(response.data.totalElements);
      setDescontos(response.data.content);
    } catch (error) {
      console.error("Erro ao obter descontos:", error);
      toastMessage("error", GET_DESCONTOS_ERROR_MESSAGE);
    }
  }

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setSize(event.rows);
    setPage(event.page);
    setFirst(event.first);
    window.scrollTo(0, 350);
  };

  async function onDelete(id: number) {
    const failMessage =
      "Ocorreu um erro ao deletar o desconto. Por favor, tente novamente mais tarde.";
    try {
      const res = await descontosService.deleteDesconto(id);

      if (res.status === 200) {
        toastMessage("success", "Desconto deletado com sucesso!");
        getDescontos(size);
      } else {
        toastMessage("error", failMessage);
      }
    } catch (error) {
      console.error("Erro ao deletar desconto:", error);
      toastMessage("error", failMessage);
    }
  }

  function toastMessage(type: "error" | "success", detail: string) {
    toast.current?.show({
      severity: type,
      summary: type === "error" ? "Erro" : "Successo",
      detail,
      life: 3000,
    });
  }

  function confirmDelete(id: number) {
    confirmDialog({
      acceptLabel: "Confirmar",
      rejectLabel: "Cancelar",
      message: "Você tem certeza que deseja deletar esse desconto?",
      header: "Atenção",
      icon: "pi pi-exclamation-triangle",
      accept: () => onDelete(id),
    });
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />

      <GestaoConveniadosContainer>
        <h2 style={{ margin: "1.5rem 0" }}>Gestão de Descontos</h2>

        <BasicCardWrapper>
          {Array.isArray(descontos) && descontos.length > 0 ? (
            descontos.map((desconto) => {
              return (
                <div style={{ position: "relative" }} key={desconto.id}>
                  <DescontoCard
                    desconto={desconto.desconto}
                    descricao={desconto.descricao}
                    endereco={desconto.endereco}
                    imagem={desconto.imagem}
                    nome={desconto.nome}
                  ></DescontoCard>

                  <HiTrash
                    size={32}
                    onClick={() => confirmDelete(desconto.id)}
                    color={defaultTheme["red-500"]}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      background: defaultTheme.white,
                      borderRadius: "50%",
                      padding: "0.25rem",
                    }}
                  />
                </div>
              );
            })
          ) : (
            <p
              style={{
                position: "absolute",
                left: "50%",
                width: "100%",
                textAlign: "center",
                transform: "translateX(-50%)",
              }}
            >
              Nenhum resultado foi encontrado
            </p>
          )}
        </BasicCardWrapper>
        <Paginator
          rows={size}
          first={first}
          totalRecords={totalRecords}
          rowsPerPageOptions={[25, 50, 75, 100]}
          onPageChange={onPageChange}
          style={{ marginTop: "1rem" }}
        />
      </GestaoConveniadosContainer>
    </>
  );
};
