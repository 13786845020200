import styled from "styled-components";

export const PartnerGrid = styled.div`
  display: grid;
  padding: 2rem;
  max-width: 1440px;
  gap: 2rem;
  margin: auto;

  overflow: hidden;
  grid-template-columns: 1fr 1fr;

  height: 100%;

  h3 {
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    font-size: 1.5rem;
  }

  @media (max-width: 768px) {
    max-height: 600px;

    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 1rem;
  }

  @media (max-width: 980px) {
    h1 {
      font-size: 2rem;
    }
  }

  @media (max-width: 520px) {
    max-height: 520px;
  }
`;

export const ThinCardWrapper = styled.div`
  display: grid;
  gap: 8px;

  height: 400px;

  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 980px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    height: 200px;
  }
`;

export const PartnerSealContainer = styled.div`
  display: flex;
  gap: 1rem;
  max-width: 1440px;
  align-items: flex-end;
  margin: auto;

  padding: 2rem;
`;
