import { AxiosResponse } from "axios";
import { IModalidade } from "../model/modalidade";
import { api } from "./api";

const url: string = "modalidades-saude";

const getModalidadeSaude = (): Promise<AxiosResponse<IModalidade[]>> => {
  return api.get(url);
};

const modalidadeSaudeService = { getModalidadeSaude };

export default modalidadeSaudeService;