import { BiStoreAlt } from "react-icons/bi";
import { TbHealthRecognition } from "react-icons/tb";

import ThinCategoryCard from "../../../../../components/cards/thin-card";
import textUtils from "../../../../../utils/textUtils";
import { partnerContent, partnerContentSaude } from "./content";
import { PartnerGrid, PartnerSealContainer, ThinCardWrapper } from "./styles";

export const Partners = () => {
  const { partner_summary } = partnerContent;
  const { partner_summary_saude } = partnerContentSaude;

  return (
    <div>
      <PartnerGrid>
        <div>
          <span style={{ display: "flex", gap: "0.5rem" }}>
            <h3>{textUtils.handleTextHigliht(partner_summary.title)}</h3>
            <BiStoreAlt size={28} color="#ff9c00"></BiStoreAlt>
          </span>
          <ThinCardWrapper>
            {partner_summary.categories.map((category, index) => {
              return (
                <ThinCategoryCard
                  nome={category.name}
                  imagem={category.img}
                  id={index}
                  key={index}
                  routePrefix="parceiros/categoria"
                />
              );
            })}
          </ThinCardWrapper>
        </div>
        <div>
          <span style={{ display: "flex", gap: "0.5rem" }}>
            <h3>{textUtils.handleTextHigliht(partner_summary_saude.title)}</h3>
            <TbHealthRecognition
              size={28}
              color="#d03119"
            ></TbHealthRecognition>
          </span>{" "}
          <ThinCardWrapper>
            {partner_summary_saude.categories.map((category, index) => {
              return (
                <ThinCategoryCard
                  nome={category.name}
                  imagem={category.img}
                  id={index}
                  key={index}
                  routePrefix="cartao-saude/categoria"
                />
              );
            })}
          </ThinCardWrapper>
        </div>
      </PartnerGrid>
      <PartnerSealContainer>
        <img src={partnerContent.affiliate_seal} alt="" />
        <p>
          {textUtils.handleTextHigliht(
            partnerContent.affiliate_seal_description,
            `bold`
          )}
        </p>
      </PartnerSealContainer>
    </div>
  );
};
