import {
  ColumnContainer,
  ColumnImageContainer,
  ColumnRedesSociais,
  FacebookIcon,
  FooterContainer,
  InstagramIcon,
  RedesSociais,
  TwitterIcon,
  YoutubeIcon,
} from "./styles";

import bannerLojas from "../../assets/images/home/banners/bannerlojas-rodape.png";

export const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <ColumnImageContainer>
        <img
          style={{ width: "100%", height: "100%" }}
          src={bannerLojas}
          alt="Lojas parceiras"
        />
      </ColumnImageContainer>

      <ColumnContainer>
        <h2>Contato</h2>
        <p>
          Rua 16 de Março, 38, loja 6, no prédio onde está a Adjuve -
          Petrópolis/RJ
        </p>
        <p>(22) 2526-6055</p>
        <p>Whatsapp: (24) 98821-0670</p>
      </ColumnContainer>

      <ColumnRedesSociais>
        <h2>Redes Sociais</h2>
        <RedesSociais>
          <a
            href="https://www.facebook.com/profile.php?id=100076079653553&mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.youtube.com/@afesptv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeIcon />
          </a>
          <a
            href="https://twitter.com/afespconvenios"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://www.instagram.com/afesppetropolis/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
        </RedesSociais>
      </ColumnRedesSociais>
    </FooterContainer>
  );
};
