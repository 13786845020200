import { AxiosResponse } from "axios";
import { IModalidade } from "../model/modalidade";
import { api } from "./api";

const url: string = "modalidades";

const getModalidade = (): Promise<AxiosResponse<IModalidade[]>> => {
  return api.get(url);
};

const getModalidadeSaude = (): Promise<AxiosResponse<IModalidade[]>> => {
  return api.get(`${url}-saude`);
};

const modalidadeService = { getModalidade, getModalidadeSaude };

export default modalidadeService;
