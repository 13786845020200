import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "../src/styles/colors/default";
import { GlobalStyle } from "../src/styles/global";
import { Footer } from "./components/footer";
import { Navbar } from "./components/navbar/navbar";
import { AuthProvider } from "./context/AuthContext";
import { SubscribeDialogProvider } from "./context/SubscribeDialogContext";
import { Router } from "./router/router";

import "primereact/resources/themes/saga-orange/theme.css";

import { PrimeReactProvider, addLocale } from "primereact/api";

function App() {
  addLocale("pt-br", {
    firstDayOfWeek: 0,
    dayNames: [
      "domingo",
      "segunda-feira",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ],
    today: "Hoje",
    clear: "Limpar",
  });

  return (
    <PrimeReactProvider>
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <AuthProvider>
            <SubscribeDialogProvider>
              <GlobalStyle />
              <Navbar />
              <Router />
              <Footer />
            </SubscribeDialogProvider>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </PrimeReactProvider>
  );
}

export default App;
