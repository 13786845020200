import styled from "styled-components";

export const CategoriesHeaderContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 4rem 1rem;
  padding-bottom: 0;

  h2 {
    font-size: 2.5rem;
    padding: 0.5rem;
  }

  p {
    max-width: 50%;
    padding: 0 0.5rem;
  }

  @media (max-width: 768px) {
    p {
      max-width: 100%;
    }
  }
`;
