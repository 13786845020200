import { Dialog } from "primereact/dialog";
import styled from "styled-components";

export const DialogContainer = styled(Dialog)`
  position: relative;
  background-color: ${(props) => props.theme.white};
  width: 90%;
  height: 70%;
  max-width: 1024px;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 4.25rem;

  p {
    font-size: 0.75rem;
  }
`;

export const InputsWrapper = styled.form`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
