import styled from "styled-components";

export const Container = styled.div`
  background: linear-gradient(
    90deg,
    rgba(206, 112, 53, 1) 0%,
    rgba(255, 177, 51, 1) 100%
  );
  color: ${(props) => props.theme.white};
  display: flex;
  justify-content: center;
  padding: 0.25rem 1.25rem;

  width: 100%;
  z-index: 99999;
  text-align: center;

  h4 {
    color: ${(props) => props.theme.white};
    font-size: 0.875rem;
    margin: 0px;
  }

  a {
    text-align: center;
    text-decoration: none;
    font-size: 0.875rem;
    margin: 0px;
    color: ${(props) => props.theme.white};

    &:hover {
      text-decoration: underline;
    }
  }
`;
