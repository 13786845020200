import styled from "styled-components";

export const Gradient = styled.div`
  background: linear-gradient(
    90deg,
    rgba(206, 112, 53, 1) 0%,
    rgba(255, 177, 51, 1) 100%
  );
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  gap: 16px;
  padding: 1rem 3.25rem;
  width: 100%;
  max-width: 1440px;
  overflow: hidden;

  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
    padding: 1rem;
  }
`;

export const StreamContainer = styled.div`
  display: grid;
  max-width: 1054px;
  display: grid;
  justify-content: center;
  align-items: center;

  grid-template-columns: 4fr 8fr;
  gap: 28px;
  min-width: 100%;

  img {
    max-width: 100%;
    filter: drop-shadow(0px 0px 50px rgba(255, 177, 51, 1));
    width: auto;
    height: auto;
    margin: auto;
  }

  @media (max-width: 1024px) {
    img {
      width: 50%;
    }
    grid-template-columns: 1fr;
  }
`;

export const Stream = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: none;
  }
`;
