import { useState } from "react";

import seller from "../../../../assets/images/home/video/afesp-character.png";
import { IPublicidades } from "../../initContainers";
import { Container, Gradient, Stream, StreamContainer } from "./styles";

export const VideoContainer = () => {
  const [home, setHome] = useState<IPublicidades | undefined>(
    mockPublicidades[0]
  );

  return (
    <Gradient>
      <Container>
        <StreamContainer>
          <img src={seller} alt="Conheça o convênio AFESP." />
          <Stream>
            <iframe
              src="https://www.youtube.com/embed/uJZe1ej_gqk?si=LN7IfVyC1A_cZFVi?loop=1"
              allow="autoplay; fullscreen; picture-in-picture; encrypted-media"
              title="Afesp Convênios"
            ></iframe>
          </Stream>
        </StreamContainer>
      </Container>
    </Gradient>
  );
};

export const mockPublicidades: IPublicidades[] = [
  {
    id: 1,
    nome: "Publicidade 1",
    imagemWeb:
      "https://files.tecnoblog.net/wp-content/uploads/2021/02/facebook-ads-e1614281703618-700x393.jpg",
    imagemMobile:
      "https://files.tecnoblog.net/wp-content/uploads/2021/02/facebook-ads-e1614281703618-700x393.jpg",
    recomendadoWeb: "Recomendado para web 1",
    recomendadoMobile: "Recomendado para mobile 1",
    linkUrl: "https://www.example.com/publicidade1",
  },
  {
    id: 2,
    nome: "Publicidade 2",
    imagemWeb:
      "https://files.tecnoblog.net/wp-content/uploads/2021/02/facebook-ads-e1614281703618-700x393.jpg",
    imagemMobile:
      "https://files.tecnoblog.net/wp-content/uploads/2021/02/facebook-ads-e1614281703618-700x393.jpg",
    recomendadoWeb: "Recomendado para web 2",
    recomendadoMobile: "Recomendado para mobile 2",
    linkUrl: "https://www.example.com/publicidade2",
  },
  // ... mais publicidades se necessário
];
