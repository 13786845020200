import styled from "styled-components";

export const Container = styled.div`
  height: fit-content;
  padding: 1rem 3.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme["gray-500"]};

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const HomeAdsDiv = styled.div`
  display: flex;
  width: 80rem;
  height: fit-content;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 1rem;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: ${(props) => props.theme["gray-500"]};
  color: ${(props) => props.theme["gray-100"]};

  @media (max-width: 1750px) {
    width: 70rem;
  }
  @media (max-width: 1200px) {
    width: 50rem;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-between;
  width: 80rem;
  border-bottom: 3px solid white;

  @media (max-width: 1750px) {
    width: 70rem;
  }
  @media (max-width: 1200px) {
    width: 50rem;
  }
  @media (max-width: 768px) {
    width: 26rem;
  }

  select {
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 8px;
    @media (max-width: 768px) {
      padding: 0.5rem;
      font-size: 1rem;
    }
  }

  h1 {
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

export const DivImageAds = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const DivAdsSloteWeb = styled.div`
  display: flex;
  width: 100%;
  min-height: 500px;
  align-items: center;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: space-around;

  h2 {
    margin: 8px;
  }
  input {
    margin: 8px;
    width: 20rem;
    padding: 6px;
    border: none;
    border-radius: 8px;
  }
  button {
    margin: 8px;
  }

  img {
    width: 160px;
    height: 458px;
    object-fit: cover;
    margin: 8px;
  }
`;

export const DivAdsSloteMobile = styled.div`
  display: flex;
  margin: 5px;
  width: 100%;
  min-height: 500px;

  margin-bottom: 24px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme["gray-400"]};

  h2 {
    margin: 8px;
  }
  input {
    margin: 8px;
    width: 20rem;
    padding: 6px;
    border: none;
    border-radius: 8px;
  }
  button {
    margin: 8px;
  }

  img {
    width: 458px;
    height: 120px;
    object-fit: cover;
    margin: 8px;

    @media (max-width: 516px) {
      scale: 0.6;
    }
  }
`;

export const DivDefault = styled.div`
  padding: 14rem;
  width: 100%;
  color: ${(props) => props.theme["gray-100"]};
`;

export const LinkAndSubmit = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`;
