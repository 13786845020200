import styled from "styled-components";

export const AddDescontoWrapper = styled.div`
  display: grid;
  grid-template-columns: 8fr 271px;
  margin: auto;
  max-width: 1440px;
  gap: 1rem;
  padding: 1rem;
  align-items: center;

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
  }
`;

export const AddConveniadoForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  label {
    width: fit-content;
    border-bottom: 2px solid ${(props) => props.theme["purple-300"]};
  }
`;

export const CustomSelect = styled.select`
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #b0b0b0;
  color: #464646;

  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme["purple-300"]};
    background-color: ${(props) => props.theme["gray-50"]};
  }
`;
