import styled from "styled-components";

export const FeedbackCardWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  margin: auto;
  margin-top: 1rem;
  padding-bottom: 1rem;
  max-width: 1440px;
`;
