import styled from "styled-components";

export const DescontoCardContainer = styled.div`
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.5);
  border: 1px solid #eaeaea;
  min-width: 100%;
  height: 25rem;
  position: relative;
`;

export const DescontoCardImage = styled.img`
  width: 100%;
  height: 10rem;
  object-fit: cover;
  object-position: center;
  border-radius: 8px 8px 0 0;
`;

export const DescontoContent = styled.div`
  padding: 0.75rem;
  padding-top: 0.25rem;
  height: 12.5rem;
  overflow: auto;
`;

export const DescontoTag = styled.p`
  background-color: ${(props) => props.theme["green-500"]};
  color: ${(props) => props.theme.white};
  padding: 0.25rem;
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 0.75rem;
  width: fit-content;
  margin-bottom: 0.5rem;
`;

export const DescontoConveniadoName = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const DescontoEndereco = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
  height: 2.5rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const DescontoPrazo = styled.div`
  position: absolute;
  bottom: 9px;
  left: 12px;
  font-size: 0.875rem;
  color: ${(props) => props.theme["purple-400"]};
  border-radius: 0.25rem;
  font-weight: 700;
`;

export const DescontoDescricao = styled.p`
  font-size: 0.875rem;
`;
