import styled from "styled-components";

export const StoreCardWrapper = styled.div`
  display: grid;
  max-width: 1440px;
  margin: auto;
  padding: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 528px) {
    grid-template-columns: 1fr;
  }
`;
