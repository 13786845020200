import emailjs from "@emailjs/browser";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { PurpleButton } from "../buttons/purpleButton";
import { InputWithIcon } from "../inputs/inputWithIcon";
import { TextArea } from "../inputs/textarea";
import { DialogContainer, InputsWrapper } from "./styles";

interface ISubscribeDialog {
  visible: boolean;
  onHide: VoidFunction;
}

export const SubscribeDialog = ({ onHide, visible }: ISubscribeDialog) => {
  const toast = useRef<Toast>(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  function sendEmail(event: any) {
    event.preventDefault();
    if (name === "" || email === "" || message === "") {
      toast.current?.show({
        severity: "info",
        summary: "Info",
        detail: "Preencha todos os campos",
        life: 3000,
      });
      return;
    }
    const templateParams = {
      from_name: name,
      message: message,
      email: email,
    };

    emailjs
      .send(
        "service_0p4d1b7",
        "template_ywoxgwd",
        templateParams,
        "C-cEvdrPHUX8CZJjr"
      )
      .then(() => {
        toast.current?.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Email enviado com sucesso!",
          life: 3000,
        });
        setName("");
        setEmail("");
        setMessage("");
        onHide();
      });
  }

  return (
    <>
      <Toast ref={toast} />
      <DialogContainer
        draggable={false}
        visible={visible}
        onHide={() => onHide()}
      >
        <h2>Preencha os campos a seguir</h2>
        <p>Entraremos em contato o mais rápido possível.</p>
        <InputsWrapper onSubmit={sendEmail}>
          <InputWithIcon
            value={email}
            onChange={setEmail}
            title="Email"
            placeholder="Digite seu email"
            icon={<MdOutlineMailOutline color="#B0B0B0" size={24} />}
          ></InputWithIcon>

          <InputWithIcon
            value={name}
            onChange={setName}
            title="Nome"
            placeholder="Digite seu nome"
            icon={<FaRegUser color="#B0B0B0" size={22} />}
          ></InputWithIcon>
          <TextArea
            title="Mensagem"
            onChange={setMessage}
            value={message}
            placeholder="Digite sua mensagem"
          />
          <PurpleButton
            title="Enviar"
            style={{ position: "absolute", bottom: "16px", right: "16px" }}
            submit={true}
          />
        </InputsWrapper>
      </DialogContainer>
    </>
  );
};
