import { CSSProperties } from "styled-components";

type HiglihtType = "default" | "bold";

function handleTextHigliht(
  value: string,
  type: HiglihtType = "default"
): JSX.Element {
  const parts = value.split(/%(.*?)%/g);
  return (
    <>
      {parts.map((part, index) => {
        return index % 2 === 1 ? highlightedText(index, part, type) : part;
      })}
    </>
  );
}

function highlightedText(index: number, part: string, type: HiglihtType) {
  const higlihtOptions: { [key: string]: CSSProperties } = {
    bold: { fontWeight: "bold" },
    default: { color: "#FF9D0E" },
  };

  return (
    <span key={index} style={higlihtOptions[type]}>
      {part ?? ""}
    </span>
  );
}

function routeFormater(route: string) {
  route = route.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return route.toLowerCase().replaceAll(" ", "-").replaceAll(",", "");
}

const textUtils = {
  handleTextHigliht,
  routeFormater,
};

export default textUtils;
