import { Carousel } from "primereact/carousel";
import { INoticia } from "../../pages/home/initContainers";
import textUtils from "../../utils/textUtils";
import { PurpleButton } from "../buttons/purpleButton";
import { content } from "./content";
import { CarrouselContainer, TextWrapper } from "./styles";

interface Props {
  newsList: INoticia[];
  onImageClick: (image: string) => void;
}

export const Carrousel = ({ newsList, onImageClick }: Props) => {
  function renderButton(item: any) {
    if (item.routerLink || item.imageBanner) {
      return (
        <PurpleButton onClick={() => onImageClick(item)} title="Saber mais" />
      );
    }
  }

  const itemTemplate = (item: any) => {
    return (
      <CarrouselContainer>
        <TextWrapper>
          <h2>{item.title}</h2>
          <h3>{textUtils.handleTextHigliht(item.subTitle, "bold")}</h3>
          <div>{renderButton(item)}</div>
        </TextWrapper>
        <img src={item.image} alt="" />
      </CarrouselContainer>
    );
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <Carousel
        value={content.content}
        numScroll={1}
        numVisible={1}
        itemTemplate={itemTemplate}
        autoplayInterval={8000}
      />
    </div>
  );
};
