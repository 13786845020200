import { IFeedback } from "../../../../../model/feedback";

interface IFeedbackContent {
  title: string;
  feedbacks: Array<IFeedback>;
}

export const feedbackContent: IFeedbackContent = {
  title: "Veja o que nossos %associados% falam sobre nós:",
  feedbacks: [
    {
      title: "Ana Oliveira",
      subtitle: "Servidora Pública",
      picture: "https://i.postimg.cc/g2Mh0Ygh/image.png",
      comment:
        "Esse cartão é tipo um super-herói! Com três coisas em um, minha vida ficou muito mais fácil. Nota máxima porque ele é muito versátil!",
      rate: 5,
    },
    {
      title: "Rafael Almeida",
      subtitle: "Servidor Público",
      picture: "https://i.postimg.cc/g2Mh0Ygh/image.png",
      comment:
        "Esse cartão merece nota máxima, sério! Além de ajudar nas compras, ainda dá vantagens na saúde. É tipo um amigo que cuida de tudo. Recomendo demais!",
      rate: 5,
    },
    {
      title: "Ricardo Pereira",
      subtitle: "Servidor Público",
      picture: "https://i.postimg.cc/g2Mh0Ygh/image.png",
      comment:
        "Sério, esse cartão é sensacional! Com três funções em um, eu me sinto o rei da praticidade. Nota máxima porque ele não podia ser melhor!",
      rate: 5,
    },
    {
      title: "Melissa Rodrigues",
      subtitle: "Servidora Pública",
      picture: "https://i.postimg.cc/g2Mh0Ygh/image.png",
      comment:
        "Esse cartão é top demais! Além de me ajudar com as compras, ainda tem ótimos preços em clínicas. Nota máxima porque ele é tudo de bom!",
      rate: 5,
    },
    {
      title: "Ana Silva",
      subtitle: "Servidora Pública",
      picture: "https://i.postimg.cc/g2Mh0Ygh/image.png",
      comment:
        "Gente, que cartão maravilhoso! Ele faz tudo e mais um pouco. Minha nota é máxima porque não tem nada que eu mudaria. Super recomendo!",
      rate: 5,
    },
    {
      title: "João Oliveira",
      subtitle: "Servidor Público",
      picture: "https://i.postimg.cc/g2Mh0Ygh/image.png",
      comment:
        "Esse cartão merece nota máxima demais! Além de ajudar nas compras, ainda dá uma mãozinha na saúde. É o combo perfeito!",
      rate: 5,
    },
    {
      title: "Gabriela Lima",
      subtitle: "Servidora Pública",
      picture: "https://i.postimg.cc/g2Mh0Ygh/image.png",
      comment:
        "Esse cartão é show! Três coisas em um só e ainda com benefícios. Minha nota é máxima porque ele é meu parceiro total!",
      rate: 5,
    },
    {
      title: "Maria Santos",
      subtitle: "Servidora Pública",
      picture: "https://i.postimg.cc/g2Mh0Ygh/image.png",
      comment:
        "Esse cartão é meu xodó! Economizo e ainda tenho vantagens na saúde. Nota máxima porque não tem o que reclamar, só elogiar!",
      rate: 5,
    },
    {
      title: "Eduardo Souza",
      subtitle: "Servidor Público",
      picture: "https://i.postimg.cc/g2Mh0Ygh/image.png",
      comment:
        "Gente, que cartão é esse?! Facilitou minha vida demais. Nota máxima porque ele é completo, não dá pra viver sem!",
      rate: 5,
    },
  ],
};
