import styled from "styled-components";

import { FaTrashAlt } from "react-icons/fa";

interface IBasicCard {
  title: string;
  subtitle: string;
  image: string;
  onDelete: VoidFunction;
}

export const BasicCard = ({ title, image, subtitle, onDelete }: IBasicCard) => {
  return (
    <BasicCardContainer>
      <img src={image} alt="" />
      <div>
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
      <FaTrashAlt
        onClick={() => onDelete()}
        style={{
          position: "absolute",
          right: "0.875rem",
          top: "0.875rem",
          color: "#FF0000",
          cursor: "pointer",
        }}
      />
    </BasicCardContainer>
  );
};

const BasicCardContainer = styled.div`
  display: flex;
  position: relative;
  gap: 0.5rem;
  box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.5);
  border: 1px solid #eaeaea;
  margin-bottom: 1rem;
  max-height: 102px;
  border-radius: 6px;
  padding-right: 1.25rem;
  width: 100%;
  min-width: 400px;
  max-width: 400px;
  margin-top: 1.5rem;

  @media (max-width: 800px) {
    max-width: 100%;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  h2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 0.875rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
