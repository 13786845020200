import styled from "styled-components";

export const StoreCardContainer = styled.div`
  background-color: #f6f6f6;
  padding: 25px 12px 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.5);
  border: 1px solid #eaeaea;
  min-width: 100%;

  h2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 48px;
    font-size: 1.25rem;
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin: 12px 0 24px 0;
  }

  p {
    font-size: 0.875rem;
    height: 55px;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h3 {
    color: ${(props) => props.theme["purple-400"]};
    font-size: 0.875rem;
  }

  h4 {
    font-size: 0.875rem;
    font-weight: 600;
    height: 2rem;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const SocialMediaWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  height: 40px;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  a {
    color: #464646;
    font-size: 0.875rem;
    font-weight: 700;
    text-decoration: none;
    transition: 0.2s ease-in-out;

    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;

    &:hover {
      color: ${(props) => props.theme["purple-400"]};
      text-decoration: underline;
    }
  }
`;
