import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { IFeedback } from "../../../../../../model/feedback";
import { FeedbackCardContainer, StarsWrapper } from "./styles";

export const FeedbackCard = ({
  title,
  subtitle,
  picture,
  comment,
  rate,
  style,
}: IFeedback) => {
  function renderStarIcons(rate: number) {
    const maxStars = 5;
    const filledStars = Math.round(rate);
    const emptyStars = maxStars - filledStars;

    const starIcons = [];

    for (let i = 0; i < filledStars; i++) {
      starIcons.push(<AiFillStar size={24} key={i} color="#FFF500" />);
    }

    for (let i = 0; i < emptyStars; i++) {
      starIcons.push(
        <AiOutlineStar size={24} key={filledStars + i} color="#FFF500" />
      );
    }

    return starIcons;
  }

  const stars = renderStarIcons(rate);

  return (
    <FeedbackCardContainer style={style}>
      <div>
        <span>
          <h3>{title}</h3>
          <h4>{subtitle}</h4>
        </span>
      </div>
      <StarsWrapper>
        {stars.map((star, index) => {
          return <span key={index}>{star}</span>;
        })}
      </StarsWrapper>
      <p>{comment}</p>
    </FeedbackCardContainer>
  );
};
