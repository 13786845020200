import { useMemo } from "react";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { IStore } from "../../../../model/store";
import { SocialMediaWrapper, StoreCardContainer } from "./styles";

export const StoreCard = ({
  nome,
  modalidade,
  endereco,
  descricao,
  instagram,
  site,
  imagem,
}: IStore) => {
  const instagramLink = useMemo(() => {
    const link = instagram.replace("@", "");
    return link;
  }, [instagram]);

  const siteLink = useMemo(() => {
    const link = site.replace("https://", "");

    return "https://" + link;
  }, [site]);

  return (
    <StoreCardContainer>
      <h2>{nome} </h2>
      <h3>{modalidade?.nome ?? ""}</h3>
      <img src={imagem} alt="" />
      <p>{descricao}</p>
      <SocialMediaWrapper>
        {instagram.length > 0 && (
          <div>
            <FaInstagram color="#CE7035" size={20} />
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.instagram.com/${instagramLink}`}
            >
              {instagram}
            </a>
          </div>
        )}
        {site.length > 0 && (
          <div>
            <AiOutlineGlobal
              color="#CE7035"
              size={20}
              style={{ minWidth: "20px" }}
            />
            <a target="_blank" rel="noreferrer" href={siteLink}>
              {site}
            </a>
          </div>
        )}
      </SocialMediaWrapper>
      <h4>{endereco}</h4>
    </StoreCardContainer>
  );
};
