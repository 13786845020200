import styled from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 90vh;
`;

export const LoginForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-height: 500px;
  width: 100%;
  padding: 24px;
  background-color: ${(props) => props.theme["gray-900"]};
  border-radius: 10px;

  span {
    position: absolute;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);
  }

  h2 {
    color: ${(props) => props.theme["gray-50"]};
    border-bottom: 3px solid ${(props) => props.theme["purple-500"]};
    width: fit-content;
    margin-bottom: 8px;
  }
`;

export const FirstBlockOfForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 180px;
`;
export const Logo = styled.div`
  display: flex;
  font-weight: bold;
  text-decoration: none;
  align-items: center;
  margin: auto;
  height: 60px;
  background-color: ${(props) => props.theme["gray-900"]};
  z-index: 2;

  color: ${(props) => props.theme.white};
  h1 {
    font-size: 1.5rem;
  }
  p {
    font-weight: 400;
    font-size: 0.75rem;
  }
`;
