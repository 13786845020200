import { AxiosResponse } from "axios";
import { IPageable, IPageableParams } from "../interfaces/model/pageable";
import { IDesconto, IDescontoInput } from "../model/descontos";
import { api } from "./api";

const url: string = "descontos";

const getDescontos = ({
  filter,
  page,
  size,
}: IPageableParams): Promise<AxiosResponse<IPageable<IDesconto>>> => {
  return api.get(url, { params: { filter, page, size } });
};

const getDescontosAleatorios = (): Promise<AxiosResponse<IDesconto[]>> => {
  return api.get(`${url}/aleatorios`);
};


const deleteDesconto = (
  id: number
): Promise<AxiosResponse<IPageable<IDesconto>>> => {
  return api.delete(`${url}/${id}`);
};

const createDesconto = (
  desconto: IDescontoInput
): Promise<AxiosResponse<IDesconto>> => {
  return api.post(url, desconto);
};

const descontosService = { getDescontos, deleteDesconto, createDesconto, getDescontosAleatorios };

export default descontosService;
