
import { useContext, useEffect, useState } from "react";
import { ILoginUsuario } from "../../interfaces/model/iUsuario";
import { PurpleButton } from "../../components/buttons/purpleButton";
import { CommonInput } from "../../components/inputs/commonInput";
import { SecureInput } from "../../components/inputs/secureInput";
import { AuthContext } from "../../context/AuthContext";
import { FirstBlockOfForm, LoginContainer, LoginForm, Logo } from "./style";

export const Login = () => {
  const { signIn } = useContext(AuthContext);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (login !== "" && password !== "") {
      setButtonIsDisabled(false);
    } else {
      setButtonIsDisabled(true);
    }
  }, [password, login]);

  async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const user: ILoginUsuario = {
      login: login,
      password: password,
    };
    try {
      setButtonIsDisabled(true);
      signIn(user);
    } catch (e) {
      console.log(e);
      // Notify.failure("Login ou senha inválido");
    } finally {
      setButtonIsDisabled(false);
    }
  }

  return (
    <LoginContainer>
      <LoginForm onSubmit={handleLogin}>
        <FirstBlockOfForm>
          <Logo>
            <h1>Afesp Convênios</h1>
          </Logo>
          <div>
            <h2>Login</h2>
            <CommonInput
              value={login}
              onChange={setLogin}
              placeholder="Digite seu login"
            />
          </div>
          <div>
            <h2>Senha</h2>
            <SecureInput
              value={password}
              onChange={setPassword}
              placeholder="Digite sua senha"
            />
          </div>
        </FirstBlockOfForm>
        <span>
          <PurpleButton title="Login" disabled={buttonIsDisabled} submit />
        </span>
      </LoginForm>
    </LoginContainer>
  );
};
