import styled from "styled-components";

interface ButtonProps {
  title: string;
  disabled?: boolean;
  onClick?: () => void;
  submit?: boolean;
  style?: React.CSSProperties;
}

export const PurpleButton = ({
  title,
  onClick,
  submit,
  style,
  disabled = false,
}: ButtonProps) => {
  return (
    <PurpleButtonContainer
      disabled={disabled}
      type={submit ? "submit" : "button"}
      onClick={onClick}
      style={style}
    >
      {title}
    </PurpleButtonContainer>
  );
};

const PurpleButtonContainer = styled.button`
  background: ${(props) => props.theme["purple-500"]};
  border: none;
  width: fit-content;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  font-weight: 700;
  color: ${(props) => props.theme.white};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme["purple-300"]};
  }

  :focus {
    background-color: ${(props) => props.theme["purple-300"]};
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;
