import styled from "styled-components";

export const HomeContainer = styled.main`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 1rem 3.25rem;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  font-size: 1.25rem;
  position: relative;
  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
    padding: 1rem;
  }
`;

export const CaroselWrapper = styled.div`
  width: 85.25%;
  @media (max-width: 1200px) {
    width: 75.25%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PositionAds = styled.div`
  position: absolute;
  right: 0;
  padding-right: 3.25rem;

  @media (max-width: 768px) {
    display: flex;
    position: static;
    padding-right: 0;
    justify-content: center;
    width: 100%;
  }
`;

export const BoxSmallCard = styled.div`
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
  color: ${(props) => props.theme.white};
  overflow-x: auto;
  flex-wrap: wrap;

  min-width: 100%;
  flex-wrap: nowrap;

  ::-webkit-scrollbar {
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 100px;
    background: ${(props) => props.theme["gray-50"]};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: ${(props) => props.theme["gray-100"]};
  }

  @media (max-width: 768px) {
    gap: 8px;
  }
`;
