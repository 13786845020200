import styled from "styled-components";

interface LoadingContainerProps {
  isLoading: boolean;
}

export const LoadingContainer = styled.div<LoadingContainerProps>`
  display: ${(props) => (props.isLoading ? "flex" : "none")};
  background-color: ${(props) => props.theme.white};
  align-items: center;
  justify-content: center;
  transition: 2s ease-in-out;
  width: 100%;
  height: 100vh;
  flex-direction: column;

  p {
    color: ${(props) => props.theme["purple-500"]};
    margin-top: 8px;
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

export const LoadingPulse = styled.div`
  justify-self: center;
  align-self: center;
  position: relative;
  width: 10px;
  height: 48px;
  background: ${(props) => props.theme["purple-300"]};
  animation: pulse 750ms infinite;
  animation-delay: 250ms;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes pulse {
    50% {
      background: white;
    }
  }

  &::before,
  ::after {
    content: "";
    position: absolute;
    display: block;
    height: 28px;
    width: 10px;
    background: ${(props) => props.theme["purple-300"]};
    top: 50%;
    transform: translateY(-50%);
    animation: pulse 750ms infinite;
  }

  &::before {
    left: -18px;
  }

  &::after {
    left: 18px;
    animation-delay: 500ms;
  }
`;
