import { useEffect, useRef, useState } from "react";
import { FaImage, FaInstagram } from "react-icons/fa";
import { SlLocationPin } from "react-icons/sl";
import { TbFileDescription } from "react-icons/tb";
import { InputWithIcon } from "../../../components/inputs/inputWithIcon";
import { IStore } from "../../../model/store";
import { StoreCard } from "../../categories/components/storeCard";
import { AddAssociatedWrapper, AddConveniadoForm } from "./styles";

import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { MdOutlineStorefront } from "react-icons/md";
import { RiImageAddLine } from "react-icons/ri";
import { PurpleButton } from "../../../components/buttons/purpleButton";
import { IModalidade } from "../../../model/modalidade";
import conveniadoService from "../../../services/conveniadoService";
import modalidadeService from "../../../services/modalidadeService";
import { defaultTheme } from "../../../styles/colors/default";

const INITIAL_MODALIDADE = { id: 0, nome: "" };

export const AddAssociated = () => {
  const toast = useRef<Toast>(null);

  const ERROR_MESSAGE =
    "Ocorreu um erro ao adicionar conveniado. Por favor, tente novamente.";
  const [nome, setNome] = useState("");
  const [endereco, setEndereco] = useState("");
  const [modalidade, setModalidade] = useState<IModalidade>(INITIAL_MODALIDADE);
  const [modalidadeId, setModalidadeId] = useState<number>(0);

  const [descricao, setDescricao] = useState("");
  const [instagram, setInstagram] = useState("");
  const [site, setSite] = useState("");
  const [imagem, setImagem] = useState("");
  const [modalidadesOptions, setModalidadesOptions] = useState<
    Array<IModalidade>
  >([]);

  useEffect(() => getModaliadades(), []);

  function createAssociated() {
    if (!isRequiredFieldsEmpty() && modalidade) {
      const store: Partial<IStore> = {
        nome,
        endereco,
        modalidade: { id: modalidadeId },
        descricao,
        instagram,
        site,
        imagem,
      };

      conveniadoService
        .saveConveniado(store)
        .then((res) => {
          if (res.status === 200) {
            toast.current?.show({
              severity: "success",
              summary: "Sucesso",
              detail: `Conveniado ${nome} adicionado com sucesso!`,
              life: 3000,
            });
            cleanStates();
          } else
            toast.current?.show({
              severity: "error",
              summary: "Erro",
              detail: ERROR_MESSAGE,
              life: 3000,
            });
        })
        .catch((error) => {
          console.error("Erro ao adicionar conveniado:", error);
          toast.current?.show({
            severity: "error",
            summary: "Erro",
            detail: ERROR_MESSAGE,
            life: 3000,
          });
        });
    }
  }

  function isRequiredFieldsEmpty() {
    if (imagem.length > 255) {
      showInfoToastMessage("A imagem não pode ultrapassar 255 caracteres.");
    }

    const requiredFields = [nome, imagem, descricao, endereco];
    let isEmpty = requiredFields.some((field) => field.trim().length === 0);
    if (isEmpty || !modalidade) {
      isEmpty = true;
      showInfoToastMessage("Por favor, preencha todos os campos obrigatórios.");
    }
    return isEmpty;
  }

  function showInfoToastMessage(detail: string) {
    toast.current?.show({
      severity: "info",
      summary: "Info",
      detail,
      life: 3000,
    });
  }

  function cleanStates() {
    const states = [
      setNome,
      setEndereco,
      setDescricao,
      setInstagram,
      setSite,
      setImagem,
    ];
    states.forEach((state) => state(""));
    setModalidade(INITIAL_MODALIDADE);
    setModalidadeId(0);
  }

  function getModaliadades() {
    try {
      modalidadeService
        .getModalidade()
        .then((res) => setModalidadesOptions(res.data));
    } catch (e) {
      toast.current?.show({
        severity: "error",
        summary: "Erro",
        detail: "Falha ao obter modalidades.",
        life: 3000,
      });
    }
  }

  function handleModalidadeChange(value: number) {
    setModalidadeId(value);
    const modalidade = modalidadesOptions.find((option) => option.id === value);
    modalidade && setModalidade(modalidade);
  }

  function openPostImage() {
    window.open("https://postimages.org/");
  }

  return (
    <>
      <Toast ref={toast} />
      <AddAssociatedWrapper>
        <AddConveniadoForm>
          <div
            style={{
              margin: "24px 0px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={{ width: "fit-content" }}>Adicionar Conveniado</h2>
            <RiImageAddLine
              size={28}
              onClick={openPostImage}
              color={defaultTheme["purple-400"]}
              style={{ cursor: "pointer" }}
              className="postimage"
              data-pr-tooltip="Acessar postimage.org"
            />
          </div>
          <InputWithIcon
            required={true}
            title="Conveniado"
            icon={<MdOutlineStorefront size={24} color="#ffb133" />}
            placeholder="Digite o nome do conveniado"
            value={nome}
            onChange={setNome}
          />

          <label>Selecione uma modalidade: *</label>
          <Dropdown
            filter={true}
            value={modalidadeId}
            onChange={(e: DropdownChangeEvent) =>
              handleModalidadeChange(e.value)
            }
            options={modalidadesOptions}
            optionLabel="nome"
            optionValue="id"
            placeholder="Selecione uma modalidade"
          />

          <InputWithIcon
            required={true}
            title="Imagem"
            icon={<FaImage size={24} color="#ffb133" />}
            placeholder="Digite a url da imagem"
            value={imagem}
            onChange={setImagem}
          />
          <InputWithIcon
            required={true}
            title="Descrição"
            icon={<TbFileDescription size={24} color="#ffb133" />}
            placeholder="Digite a descrição do conveniado"
            value={descricao}
            onChange={setDescricao}
          />

          <InputWithIcon
            title="Instagram"
            icon={<FaInstagram size={24} color="#ffb133" />}
            placeholder="Digite o @ do instagram do conveniado"
            value={instagram}
            onChange={setInstagram}
          />
          <InputWithIcon
            title="Site"
            icon={<MdOutlineStorefront size={24} color="#ffb133" />}
            placeholder="Digite a url do site do conveniado"
            value={site}
            onChange={setSite}
          />
          <InputWithIcon
            required={true}
            title="Endereço"
            icon={<SlLocationPin size={24} color="#ffb133" />}
            placeholder="Digite o endereço do conveniado"
            value={endereco}
            onChange={setEndereco}
          />
        </AddConveniadoForm>
        <div style={{ maxHeight: "502px" }}>
          <StoreCard
            endereco={endereco}
            modalidade={modalidade}
            descricao={descricao}
            imagem={imagem}
            instagram={instagram}
            nome={nome}
            site={site}
            id={0}
          />
        </div>
        <PurpleButton title="Adicionar" onClick={() => createAssociated()} />
      </AddAssociatedWrapper>
    </>
  );
};
