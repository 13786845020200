import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;

    -webkit-font-smoothing: antialiased;
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    
    ::-webkit-scrollbar-track {
      background: ${(props) => props.theme["gray-50"]};
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme["purple-300"]};
      border-radius: 100px;
    }
  }
  
  

  body{
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;

    overflow-x: hidden;

    input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }
  }
  body, input, textarea, button{
    font: 400 1rem Montserrat, sans-serif
  }
  

  button{
    cursor: pointer;
  }

  &:hover:disabled button{
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
  html {
    font-size: 87.5%;
  }

}

h1, h2,h3,h4,h5,h6{
  color: #464646;
  padding: 0;
  margin: 0;
}

p{
  color: #3E3E3E;
  padding: 0;
  margin: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    width: 1rem;
    height: 1rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
}

.p-carousel-indicators {
    padding-bottom:0
}


.p-carousel-next, .p-carousel-prev {
  display: none !important;
}

`;
