import {
  DescontoCardContainer,
  DescontoCardImage,
  DescontoContent,
  DescontoDescricao,
  DescontoEndereco,
  DescontoTag,
} from "./styles";

interface IDescontoCardProps {
  nome: string;
  endereco: string;
  desconto: string;
  descricao: string;
  imagem: string;
}

export const DescontoCard = ({
  desconto,
  endereco,
  imagem,
  nome,
  descricao,
}: IDescontoCardProps) => {
  return (
    <DescontoCardContainer>
      <DescontoCardImage src={imagem} />
      <DescontoContent>
        <h4>{nome}</h4>
        <DescontoEndereco>{endereco}</DescontoEndereco>
        {desconto && <DescontoTag>{desconto}</DescontoTag>}
        <DescontoDescricao>{descricao}</DescontoDescricao>
      </DescontoContent>
    </DescontoCardContainer>
  );
};
