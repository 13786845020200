import { PlaceHolderContainer } from "./styles";

interface Props {
  image: string;
  image2: string;
  link: string;
}

export const AdsPlaceHolder = ({ image, image2, link }: Props) => {
  function handleClick() {
    window.open(link, "_blank");
  }

  return (
    <PlaceHolderContainer onClick={handleClick} image={image} image2={image2} />
  );
};
