import React from "react";
import { useNavigate } from "react-router";
import { ICategoria } from "../../../model/categoria";
import textUtils from "../../../utils/textUtils";
import { InfoThinCategoryContainer, ThinCategoryCardContainer } from "./styles";

export const ThinCategoryCard = ({ nome, imagem, routePrefix }: ICategoria) => {
  const navigate = useNavigate();

  const handleClick = React.useCallback(() => {
    navigate(`/${routePrefix}/${textUtils.routeFormater(nome)}`);
  }, [navigate, routePrefix, nome]);

  return (
    <ThinCategoryCardContainer onClick={handleClick}>
      <InfoThinCategoryContainer title={nome} image={imagem ?? ""}>
        <h5>{nome}</h5>
      </InfoThinCategoryContainer>
    </ThinCategoryCardContainer>
  );
};

export default ThinCategoryCard;
