import { Container } from "./styles";

export const ContentNumberInit = () => {
  const phoneNumber = "(24) 2017-7378";
  return (
    <Container>
      <a href={`tel:${phoneNumber}`}>
        <h4>Associe-se Agora {phoneNumber}</h4>
      </a>
    </Container>
  );
};
