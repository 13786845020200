export const defaultTheme = {
  white: "#fff",

  "gray-50": "#eeeeee",
  "gray-100": "#B9B9C1",
  "gray-300": "#838390",
  "gray-400": "#52525C",
  "gray-500": "#3A3A41",
  "gray-700": "#222226",
  "gray-900": "#0A0A0B",

  "green-300": "#00B37E",
  "green-500": "#00875F",
  "green-700": "#015F43",

  "purple-100": "#B3ADFA",
  "purple-300": "#ffb133",
  "purple-400": "#ff9c00",
  "purple-500": "#CE7035",
  "purple-700": "#a45728",
  "purple-900": "#550831",

  "red-100": "#FF9999",
  "red-300": "#FF6666",
  "red-400": "#FF3333",
  "red-500": "#FF0000",
  "red-700": "#CC0000",
  "red-900": "#990000",
} as const;
