import { AxiosResponse } from "axios";
import { IPublicidades } from "./../interfaces/model/iPublicidade";
import { api } from "./api";

const url: string = "farofa-bahia/";

const getAllAds = (): Promise<AxiosResponse<IPublicidades[]>> => {
  return api.get(url);
};

const getAdsId = (id: number): Promise<AxiosResponse<IPublicidades>> => {
  return api.get(url + id);
};

const postAds = (Ads: IPublicidades): Promise<AxiosResponse> => {
  return api.post(url, Ads);
};

const putAds = (id: number, Ads: IPublicidades): Promise<AxiosResponse> => {
  return api.put(url + id, Ads);
};

const deleteAds = (id: number): Promise<AxiosResponse> => {
  return api.delete(url + id);
};

const publicidadesService = {
  getAllAds,
  getAdsId,
  postAds,
  putAds,
  deleteAds,
};

export default publicidadesService;
