import styled from "styled-components";

export const GestaoConveniadosContainer = styled.div`
  max-width: 1440px;
  padding: 1rem;
  margin: auto;
`;

export const Container = styled.div`
  max-width: 1440px;
  gap: 0.5rem;
  margin: auto;
  display: flex;
  flex-direction: row;

`;

export const BasicCardWrapper = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  width: 100%;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 490px) {
    grid-template-columns: 1fr;
  }
`;

export const DescontoCardContainer = styled.div`
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.5);
  border: 1px solid #eaeaea;
  min-width: 100%;
  height: 25rem;
  position: relative;
`;

export const DescontoCardImage = styled.img`
  width: 100%;
  height: 10rem;
  object-fit: cover;
  object-position: center;
  border-radius: 8px 8px 0 0;
`;

