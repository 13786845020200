import { useMemo } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import {
  categoriesKeyAndValue,
  staticCategoriesSaude,
} from "../../../../assets/content";
import ThinCategoryCard from "../../../../components/cards/thin-card";
import { CategoryCardContainer, CategoryCardWrapper } from "./styles";

export const CategoriesHealthStickyCard = () => {
  const local = useLocation();

  const currentCategory = useMemo(() => {
    const name = local.pathname.replace("/cartao-saude/categoria/", "");
    window.scrollTo(0, 0);
    return categoriesKeyAndValue[name] ?? "Todos";
  }, [local.pathname]);

  return (
    <CategoryCardContainer>
      <CategoryCardWrapper>
        {staticCategoriesSaude.map((category, index) => {
          return (
            <div key={index} style={{ minWidth: "275px" }}>
              <ThinCategoryCard
                nome={category.name}
                imagem={category.img}
                id={index}
                routePrefix="cartao-saude/categoria"
              />
            </div>
          );
        })}
      </CategoryCardWrapper>
      {currentCategory !== "Todos" && (
        <p>
          A categoria selecionada é: <strong>{currentCategory}</strong>
        </p>
      )}
      {currentCategory !== "Todos" && (
        <Link
          style={{ color: "#ff9c00", width: "fit-content" }}
          to="/cartao-saude"
        >
          Limpar
        </Link>
      )}
    </CategoryCardContainer>
  );
};
