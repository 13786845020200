import { Route, Routes } from "react-router";
import { PageNotFound } from "../components/pageNotFound";
import { Admin } from "../pages/admin";
import { Home } from "../pages/home/container";
import { PostAds } from "../pages/postsAds";

import { CategoriesHealthPage } from "../pages/categories-health/containers";
import { CategoriesPage } from "../pages/categories/containers";
import { ListagemDescontos } from "../pages/descontos";
import { AddAssociated } from "../pages/private/add-associated";
import { AddAssociatedSaude } from "../pages/private/add-associated-saude";
import { AdicionarDesconto } from "../pages/private/adicionar-desconto";
import { GestaoConveniadosSaude } from "../pages/private/gestao-conveniado-saude/container";
import { GestaoConveniados } from "../pages/private/gestao-conveniado/container";
import { GestaoDescontos } from "../pages/private/gestao-descontos/container";

export const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/" element={<Home />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/ads/post" element={<PostAds />} />
      <Route path="/*" element={<PageNotFound isPageNotFound />} />
      <Route path="/parceiros" element={<CategoriesPage />} />
      <Route
        path="/parceiros/categoria/:category"
        element={<CategoriesPage />}
      />
      <Route path="/cartao-saude" element={<CategoriesHealthPage />} />
      <Route
        path="/cartao-saude/categoria/:category"
        element={<CategoriesHealthPage />}
      />
      <Route path="/conveniado/gestao" element={<GestaoConveniados />} />
      <Route path="/conveniado/adicionar" element={<AddAssociated />} />
      <Route
        path="/conveniado-saude/adicionar"
        element={<AddAssociatedSaude />}
      />
      <Route
        path="/conveniado/gestao-saude"
        element={<GestaoConveniadosSaude />}
      />
      <Route path="/descontos/adicionar" element={<AdicionarDesconto />} />
      <Route path="/descontos/gestao" element={<GestaoDescontos />} />
      <Route path="/descontos" element={<ListagemDescontos />} />
    </Routes>
  );
};
