import styled from "styled-components";

export const CarrouselContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;

  height: 460px;
  align-items: end;
  justify-content: space-between;
  border-radius: 20px;

  @media (max-width: 768px) {
    height: 340px;
  }

  img {
    background-color: ${(props) => props.theme["purple-300"]};
    object-position: center;
    object-fit: cover;
    height: 100%;
    width: 50%;
    border-radius: inherit;
    position: absolute;
    right: 0;

    @media (max-width: 768px) {
      height: 80%;
    }
  }
`;

export const TextWrapper = styled.div`
  position: relative;
  width: 106%;
  height: 80%;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  gap: 0.75rem;
  background: rgb(206, 112, 53);
  background: linear-gradient(
    90deg,
    rgba(206, 112, 53, 1) 0%,
    rgba(255, 156, 0, 1) 35%,
    rgba(255, 177, 51, 1) 100%
  );
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;

  h2 {
    font-weight: 500;

    padding-right: 2rem;
    width: 100%;
    height: fit-content;

    margin: 0;
    color: ${(props) => props.theme.white};

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  h3 {
    font-weight: 500;
    padding-right: 2rem;
    width: 100%;

    margin: 0;
    color: ${(props) => props.theme.white};

    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }

  div {
    position: absolute;
    bottom: 20px;
    left: 20px;
    box-shadow: red;
    /* box-shadow: 0px 0px 73px -5px red; */
    width: fit-content;
    border-radius: 8px;
  }
`;
