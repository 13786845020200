import banner3 from "./../../assets/images/home/banners/MULTISUOOUROCARd2.png";
import carrosel1 from "./../../assets/images/home/carrosel/CARROSSEL001.png";
import carrosel2 from "./../../assets/images/home/carrosel/CARROSSEL002.png";
import carrosel4 from "./../../assets/images/home/carrosel/CARROSSEL004.png";
import carrosel6 from "./../../assets/images/home/carrosel/CARROSSEL006.png";
import carrosel3 from "./../../assets/images/home/carrosel/CARROSSEL03.png";

export const content = {
  content: [
    {
      title: "Descontos em lojas parceiras de vários segmentos.",
      subTitle:
        "Descontos exclusivos para pagamento em dinheiro (espécie, débito e pix).",
      image: carrosel2,
      routerLink: "/descontos",
    },
    {
      title: "Cartão Saúde",
      subTitle:
        "Já imaginou ter três opções diferentes de assistência em saúde e que possam caber no seu bolso?",
      image: carrosel3,
      routerLink: "cartao-saude",
    },
    {
      title: "Rede de lojas conveniadas",
      subTitle:
        "São diversos estabelecimentos conveniados que aceitam o Cartão Afesp MultiusoCard para parcelamento em folha de pagamento.",
      image: carrosel4,
      routerLink: "/parceiros",
    },

    {
      title: "Você sabia que a Afesp TV tem um canal com programação local?",
      subTitle:
        "Jornalismo, entretenimento, esporte, saúde e muito mais.  Sintonize no canal 15.6 digital e 14 analógico via Speed Fiber.",
      image: carrosel6,
      routerLink: "https://www.afesptv.com.br/",
    },
    {
      title: "Cartão Afesp MultiusoCard Três em Um.",
      subTitle: "Descubra todos os benefícios que o cartão pode te oferecer.",
      image: carrosel1,
      imageBanner: [banner3],
    },
  ],
};
