export function scrollUp() {
  window.scrollTo(0, 0);
}

export function goToPath(path: string) {
  window.location.href = path;
}

export function findAdsByName({ nome, array }: any) {
  for (const object of array) {
    if (object.nome === nome) {
      return object;
    }
  }
  return null;
}
