import {
  staticCategories,
  staticCategoriesSaude,
} from "../../../../../assets/content";
import bicoDaBota from "../../../../../assets/images/home/partners/parceiros/bico-da-bota.png";
import dib from "../../../../../assets/images/home/partners/parceiros/dib-supermercados.png";
import doctorMed from "../../../../../assets/images/home/partners/parceiros/doctor-med.png";
import giseleJeans from "../../../../../assets/images/home/partners/parceiros/gisele-jeans.png";
import liabella from "../../../../../assets/images/home/partners/parceiros/liabella.png";
import medicMais from "../../../../../assets/images/home/partners/parceiros/medic-mais.png";
import nobel from "../../../../../assets/images/home/partners/parceiros/nobel.png";
import petroVerde from "../../../../../assets/images/home/partners/parceiros/petro-verde.png";
import tricell from "../../../../../assets/images/home/partners/parceiros/tricell.png";
import unimed from "../../../../../assets/images/home/partners/parceiros/unimed.png";
import viaDigital from "../../../../../assets/images/home/partners/parceiros/via-digital.png";
import zTec from "../../../../../assets/images/home/partners/parceiros/z-tec.png";
import seloConveniado from "../../../../../assets/images/home/partners/selo-conveniado 2.png";

export const partnerContent = {
  title:
    "Confira abaixo algumas parcerias na qual você poderá aproveitar aproveitar %benefícios exclusivos%.",
  partner_summary: {
    title: "Cartão %Parcelamento%",
    categories: staticCategories,
  },
  partners: [
    unimed,
    nobel,
    bicoDaBota,
    doctorMed,
    liabella,
    viaDigital,
    tricell,
    zTec,
    dib,
    giseleJeans,
    medicMais,
    petroVerde,
  ],
  affiliate_seal: seloConveniado,
  affiliate_seal_description:
    "Foi há algum estabelecimento e viu o %selo de conveniado%? Se sim, diversos benefícios esperam por você.",
};

export const partnerContentSaude = {
  title:
    "Confira abaixo algumas parcerias na qual você poderá aproveitar aproveitar %benefícios exclusivos%.",
  partner_summary_saude: {
    title: "Cartão %Saúde%",
    categories: staticCategoriesSaude,
  },
  partners: [
    unimed,
    nobel,
    bicoDaBota,
    doctorMed,
    liabella,
    viaDigital,
    tricell,
    zTec,
    dib,
    giseleJeans,
    medicMais,
    petroVerde,
  ],
  affiliate_seal: seloConveniado,
  affiliate_seal_description:
    "Foi há algum estabelecimento e viu o %selo de conveniado%? Se sim, diversos benefícios esperam por você.",
};
