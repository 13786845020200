import { ChangeEvent, ReactNode } from "react";
import { TextInputProps } from "../props";
import { InputContainer } from "./styles";

interface IInputWithIcon extends TextInputProps {
  icon: ReactNode;
  title: string;
  required?: boolean;
}

export const InputWithIcon = ({
  icon,
  title,
  onChange,
  placeholder,
  value,
  required,
}: IInputWithIcon) => {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value);
  }

  return (
    <InputContainer>
      <p>
        {title} {required && " *"}
      </p>
      <span>
        <div>{icon}</div>
        <input
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={(e) => handleChange(e)}
        />
      </span>
    </InputContainer>
  );
};
