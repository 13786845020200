import { useEffect, useState } from "react";
import { TextAreaContainer } from "./styles";

interface TextBoxProps {
  title: string;
  height?: string;
  value: string;
  onChangeText: (text: string) => void;
  required?: boolean;
  maxLength?: number;
}

export const TextBox = ({
  title,
  onChangeText,
  height = "fit-content",
  value,
  required,
  maxLength = Infinity,
}: TextBoxProps) => {
  const [text, setText] = useState(value);
  const [charCount, setCharCount] = useState(value.length);

  useEffect(() => {
    setText(value);
    setCharCount(value.length);
  }, [value]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      const startPos = event.currentTarget.selectionStart || 0;
      const endPos = event.currentTarget.selectionEnd || 0;
      const newText = `${text.slice(0, startPos)}\n${text.slice(endPos)}`;
      setText(newText);
      onChangeText(newText);
      event.preventDefault();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setText(value);
      setCharCount(value.length);
      onChangeText(value);
    }
  };

  return (
    <TextAreaContainer height={height}>
      <label>{title}</label>
      <p
        style={{
          color: charCount === maxLength ? "#FF3333" : "#830c4b",
          display: maxLength === Infinity ? "none" : "block",
        }}
      >
        {charCount}/{maxLength}
      </p>
      <textarea
        required={required ? required : false}
        value={text}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        maxLength={maxLength}
      />
    </TextAreaContainer>
  );
};
