import styled from "styled-components";

interface Props {
  image: string;
}

export const ThinCategoryCardContainer = styled.div`
  height: 80px;
  width: 100%;
  padding: 8px;

  @media (max-width: 768px) {
    height: 70px;
  }
`;

export const InfoThinCategoryContainer = styled.div<Props>`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  color: ${(props) => props.theme.white};

  min-height: 80px;
  min-width: fit-content;
  max-width: 100%;
  border-radius: 10px;

  padding-left: 12px;

  background: linear-gradient(
      89.73deg,
      rgba(0, 0, 0, 0.85) 17.79%,
      rgba(0, 0, 0, 0) 98.75%
    ),
    url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  transition: all 0.2s ease-in-out;

  &&:hover {
    transform-origin: center;
    transform: scale(1.02);
    z-index: 2;
  }
  @media (min-width: 940px) {
    margin-right: 4px;
  }

  h5 {
    font-size: 0.875rem;
    color: ${(props) => props.theme.white};
    width: 100%;
  }

  @media (max-width: 940px) {
    margin-right: 0px;
    h5 {
      min-width: 180px;
    }
  }

  @media (max-width: 768px) {
    min-height: 70px;
  }
`;
