import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import styled from "styled-components";

export const FooterContainer = styled.div`
  color: ${(props) => props.theme.white};
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 3rem 0rem;
  margin: auto;
  width: 100%;
  max-width: 1440px;
  overflow: hidden;
  justify-content: space-evenly;
  overflow: hidden;

  p {
    color: ${(props) => props.theme["gray-100"]};
  }
`;

export const ColumnImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 30%;
  min-width: 620px;
  padding: 1rem;
  align-items: left;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 30%;
  min-width: 240px;
  padding: 1rem;
  align-items: left;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
    align-items: center;
    text-align: center;
  }

  h2 {
    color: ${(props) => props.theme["gray-500"]};
    border-bottom: 3px solid ${(props) => props.theme["purple-400"]};
    width: fit-content;
  }

  p {
    margin: 0.5rem 0rem;
    flex-wrap: wrap;
    color: ${(props) => props.theme["gray-400"]};
  }
`;

export const ColumnRedesSociais = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30%;
  min-width: 240px;
  align-items: center;
  padding: 1rem;
  margin: 0rem 1rem 0rem 1rem;

  @media screen and (max-width: 1340px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  @media screen and (max-width: 587px) {
    min-width: 100%;
  }

  h2 {
    border-bottom: 2.5px solid ${(props) => props.theme.white};
    width: fit-content;
  }
`;

export const RedesSociais = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  @media screen and (max-width: 1340px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const FacebookIcon = styled(FaFacebook)`
  color: ${(props) => props.theme["gray-100"]};
  width: 28px;
  height: auto;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme["purple-400"]};
  }
`;
export const TwitterIcon = styled(FaXTwitter)`
  color: ${(props) => props.theme["gray-100"]};
  width: 28px;
  height: auto;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme["purple-400"]};
  }
`;
export const InstagramIcon = styled(FaInstagram)`
  color: ${(props) => props.theme["gray-100"]};
  width: 28px;
  height: auto;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme["purple-400"]};
  }
`;
export const YoutubeIcon = styled(FaYoutube)`
  color: ${(props) => props.theme["gray-100"]};
  width: 28px;
  height: auto;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme["purple-400"]};
  }
`;
