import artigosInfantis from "../images/home/partners/categories/artigos-infantis.jpg";
import camaMesaBanho from "../images/home/partners/categories/cama-mesa-e-banho.webp";
import clinicasMedicas from "../images/home/partners/categories/clinicas-medicas.webp";
import consultorioMedico from "../images/home/partners/categories/consultorio-medico.jpg";
import especialidadeMedica from "../images/home/partners/categories/especialidade-medica.webp";
import farmacia from "../images/home/partners/categories/farmacia.jpg";
import informatica from "../images/home/partners/categories/informatica.jpeg";
import laboratorios from "../images/home/partners/categories/laboratorio.jpg";
import livrariasPapelarias from "../images/home/partners/categories/livrarias-papelarias.jpg";
import lojaFerragens from "../images/home/partners/categories/loja-ferragens.jpg";
import lojaFestas from "../images/home/partners/categories/loja-festas.webp";
import lojasDeModa from "../images/home/partners/categories/loja-moda.jpeg";
import lojaDeRacao from "../images/home/partners/categories/loja-racoes.jpg";
import lojasDeMoveis from "../images/home/partners/categories/lojas-de-moveis.jpg";
import materialDeConstrucao from "../images/home/partners/categories/material-de-construcao.png";
import mercado from "../images/home/partners/categories/mercado.jpg";
import oticaRelojoaria from "../images/home/partners/categories/otica-relojoaria.jpeg";
import planoSaude from "../images/home/partners/categories/plano-saude.jpg";
import salaoBeleza from "../images/home/partners/categories/salao-beleza.jpeg";
import vestuario from "../images/home/partners/categories/vestuario.jpg";

export const staticCategories = [
  { id: 1, name: "Drogarias, farmácias e manipulação", img: farmacia },
  { id: 2, name: "Mercados e açougues", img: mercado },
  {
    id: 4,
    name: "Material de Contrução e elétrica",
    img: materialDeConstrucao,
  },
  { id: 5, name: "Artigos Infantis", img: artigosInfantis },
  { id: 6, name: "Clínicas médicas e odontológicas", img: clinicasMedicas },
  { id: 8, name: "Lojas de móveis", img: lojasDeMoveis },
  { id: 9, name: "Lojas de Ração", img: lojaDeRacao },
  { id: 10, name: "Vestuário e acessórios", img: vestuario },
  { id: 11, name: "Moda", img: lojasDeModa },
  { id: 12, name: "Cama, mesa e banho", img: camaMesaBanho },
  { id: 13, name: "Óticas e Relojoarias", img: oticaRelojoaria },
  { id: 14, name: "Salão de beleza", img: salaoBeleza },
  { id: 15, name: "Livrarias e papelarias", img: livrariasPapelarias },
  { id: 3, name: "Informática", img: informatica },
  { id: 16, name: "Loja de ferragens", img: lojaFerragens },
  { id: 17, name: "Loja de festa", img: lojaFestas },
  { id: 18, name: "Planos de saúde", img: planoSaude },
];

export const staticCategoriesSaude = [
  { id: 1, name: "Clínica médica", img: clinicasMedicas },
  { id: 2, name: "Consultórios", img: consultorioMedico },
  { id: 3, name: "Laboratórios", img: laboratorios },
  { id: 4, name: "Especialidades", img: especialidadeMedica },
];

export const categoriesKeyAndValue: Record<string, string> = {
  "drogarias-farmacias-e-manipulacao": "Drogarias, farmácias e manipulação",
  "mercados-e-acougues": "Mercados e açougues",
  "material-de-contrucao-e-eletrica": "Material de Contrução e elétrica",
  "artigos-infantis": "Artigos infantis",
  "clinicas-medicas-e-odontologicas": "Clínicas médicas e odontológicas",
  "lojas-de-moveis": "Lojas de móveis",
  "lojas-de-racao": "Lojas de ração",
  "vestuario-e-acessorios": "Vestuário e acessórios",
  moda: "Moda",
  "cama-mesa-e-banho": "Cama, mesa e banho",
  "oticas-e-relojoarias": "Óticas e Relojoarias",
  "salao-de-beleza": "Salão de beleza",
  "livrarias-e-papelarias": "Livrarias e papelarias",
  informatica: "Informática",
  "loja-de-ferragens": "Loja de ferragens",
  "loja-de-festa": "Loja de festa",
  "planos-de-saude": "Planos de saúde",
  "clinica-medica": "Clínica médica",
  consultorios: "Consultórios",
  laboratorios: "Laboratórios",
  especialidades: "Especialidades",
};
