import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { useRef, useState } from "react";
import { FaImage } from "react-icons/fa";
import { MdAttachMoney, MdOutlineStorefront } from "react-icons/md";
import { RiImageAddLine } from "react-icons/ri";
import { TbFileDescription } from "react-icons/tb";
import { PurpleButton } from "../../../components/buttons/purpleButton";
import { DescontoCard } from "../../../components/descontoCard";
import { InputWithIcon } from "../../../components/inputs/inputWithIcon";
import { IDescontoInput } from "../../../model/descontos";
import descontosService from "../../../services/descontosService";
import { defaultTheme } from "../../../styles/colors/default";
import { AddConveniadoForm, AddDescontoWrapper } from "./styles";

export const AdicionarDesconto = () => {
  const toast = useRef<Toast>(null);

  const ERROR_MESSAGE =
    "Ocorreu um erro ao adicionar desconto. Por favor, tente novamente.";
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [endereco, setEndereco] = useState("");
  const [desconto, setDesconto] = useState("");
  const [imagem, setImagem] = useState("");

  function createDiscount() {
    if (!isRequiredFieldsEmpty()) {
      const store: IDescontoInput = {
        nome,
        endereco: "",
        dataCriacao: new Date().toISOString(),
        descricao,
        contato: "",
        desconto,
        imagem,
      };

      descontosService
        .createDesconto(store)
        .then((res) => {
          if (res.status === 200) {
            const message = `Desconto ${nome} adicionado com sucesso!`;
            showToastMessage("success", message);
            cleanStates();
          } else {
            showToastMessage("error", ERROR_MESSAGE);
          }
        })
        .catch(() => showToastMessage("error", ERROR_MESSAGE));
    }
  }

  function isRequiredFieldsEmpty(): boolean {
    if (imagem.length > 255) {
      showInfoToastMessage("A imagem não pode ultrapassar 255 caracteres.");
      return false;
    }

    if (descricao.length > 255) {
      showInfoToastMessage("A descrição não pode ultrapassar 255 caracteres.");
      return false;
    }

    const requiredFields = [nome, imagem, descricao];
    let isEmpty = requiredFields.some((field) => field.trim().length === 0);
    if (isEmpty) {
      showInfoToastMessage("Por favor, preencha todos os campos obrigatórios.");
    }

    return isEmpty;
  }

  function cleanStates() {
    const states = [setNome, setDescricao, setDesconto, setImagem, setEndereco];
    states.forEach((state) => state(""));
  }

  function showToastMessage(type: "success" | "error", detail: string) {
    toast.current?.show({
      severity: type,
      summary: type === "error" ? "Erro" : "Sucesso",
      detail,
      life: 3000,
    });
  }

  function showInfoToastMessage(detail: string) {
    toast.current?.show({
      severity: "info",
      summary: "Info",
      detail,
      life: 3000,
    });
  }

  function openPostImage() {
    window.open("https://postimages.org/");
  }

  return (
    <>
      <Tooltip target=".postimage" />
      <Toast ref={toast} />
      <AddDescontoWrapper>
        <AddConveniadoForm>
          <div
            style={{
              margin: "24px 0px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={{ width: "fit-content" }}>Adicionar Desconto</h2>
            <RiImageAddLine
              size={28}
              onClick={openPostImage}
              color={defaultTheme["purple-400"]}
              style={{ cursor: "pointer" }}
              className="postimage"
              data-pr-tooltip="Acessar postimage.org"
            />
          </div>

          <InputWithIcon
            required={true}
            title="Nome"
            icon={<MdOutlineStorefront size={24} color="#CE7035" />}
            placeholder="Digite o nome do desconto"
            value={nome}
            onChange={setNome}
          />

          <InputWithIcon
            required={true}
            title="Endereço"
            icon={<MdOutlineStorefront size={24} color="#CE7035" />}
            placeholder="Digite o endereço"
            value={endereco}
            onChange={setEndereco}
          />

          <InputWithIcon
            required={true}
            title="Imagem"
            icon={<FaImage size={24} color="#CE7035" />}
            placeholder="Digite a url da imagem"
            value={imagem}
            onChange={setImagem}
          />

          <InputWithIcon
            title="Desconto"
            icon={<MdAttachMoney size={24} color="#CE7035" />}
            placeholder="Digite o texto de desconto"
            value={desconto}
            onChange={setDesconto}
          />

          <InputWithIcon
            required={true}
            title="Descrição"
            icon={<TbFileDescription size={24} color="#CE7035" />}
            placeholder="Digite a descrição do desconto"
            value={descricao}
            onChange={setDescricao}
          />
        </AddConveniadoForm>
        <div style={{ maxHeight: "502px" }}>
          <DescontoCard
            desconto={desconto}
            imagem={imagem}
            endereco={endereco}
            nome={nome}
            descricao={descricao}
          ></DescontoCard>
        </div>
        <PurpleButton title="Adicionar" onClick={() => createDiscount()} />
      </AddDescontoWrapper>
    </>
  );
};
