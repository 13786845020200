import jwtDecode from "jwt-decode";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AuthContextType,
  AuthProviderProps,
} from "../interfaces/model/authContextTypes";
import { ITokenDecoded } from "../interfaces/model/iToken";
import { ILoginUsuario, IUsuario } from "../interfaces/model/iUsuario";

import { api } from "../services/api";
import usuarioService from "../services/usuarioService";

const LOCAL_STORAGE_TOKEN_KEY = "@afesp-tv-token";
const LOCAL_STORAGE_USER_KEY = "@afesp-tv-usuario";

export const AuthContext = createContext<AuthContextType>({
  token: null,
  user: null,
  signIn: async () => {},
  signOut: () => {},
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    () => localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || null
  );

  const [user, setUser] = useState<IUsuario | null>(() =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) || "null")
);

  const navigate = useNavigate();

  useEffect(() => {
    token !== null
      ? (api.defaults.headers.common["Authorization"] = "Bearer " + token)
      : delete api.defaults.headers.common.Authorization;
  }, [token]);

  const signIn = useCallback(async (login: ILoginUsuario) => {
    try {
      const response = await usuarioService.login(login);
      const token = response.data.token;
      const decoded: ITokenDecoded = await jwtDecode(token) as ITokenDecoded;

      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
      localStorage.setItem(LOCAL_STORAGE_USER_KEY, decoded.user);

      setToken(token);
      setUser(JSON.parse(decoded.user));

      navigate("/");
    } catch (e) {
      console.log(e);
      // Notify.failure("Login ou senha inválido");
    }
  }, [navigate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function signOut() {
    setToken(null);
    setUser(null);
    localStorage.clear();
    navigate("/login");
  }

  return useMemo(
    () => (
      <AuthContext.Provider
        value={{
          token,
          user,
          signIn,
          signOut,
        }}
      >
        {children}
      </AuthContext.Provider>
    ),
    [token, user, signIn, signOut, children]
  );
};
