import { ChangeEvent } from "react";
import { TextInputProps } from "../props";
import { TextAreaContainer } from "./styles";

export interface ITextArea extends TextInputProps {
  title: string;
}

export const TextArea = ({
  onChange,
  placeholder,
  value,
  title,
}: ITextArea) => {
  function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
    onChange(event.target.value);
  }

  return (
    <TextAreaContainer>
      <p>{title}</p>
      <span>
        <textarea
          value={value}
          placeholder={placeholder}
          onChange={(e) => handleChange(e)}
        />
      </span>
    </TextAreaContainer>
  );
};
