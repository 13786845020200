import styled from "styled-components";

export const FeedbackCardContainer = styled.div`
  background-color: #f2f2f2;
  margin: auto;
  border-radius: 28px;
  height: 348px;
  min-width: 250px;
  padding: 1.875rem 1.5rem;
  box-shadow: 0px 8px 8px -5px #b1b1b1;
  border: 1px solid #eaeaea;

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
  }

  h4 {
    font-size: 0.875rem;
    font-weight: 600;
  }
  span {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  div {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    max-height: 84px;
  }
`;

export const StarsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0;
`;
