import { CgGhostCharacter } from "react-icons/cg";
import { PageNotFoundContainer } from "./styles";

interface Props {
  isPageNotFound: boolean;
}

export const PageNotFound = ({ isPageNotFound }: Props) => {
  return (
    <PageNotFoundContainer isPageNotFound={isPageNotFound}>
      <CgGhostCharacter size={100} color={"#ff9c00"} />
      <div>
        <h2>
          Hmmmm... <br /> você parece perdido
        </h2>
        <p>a página que você buscou não foi encontrada</p>
      </div>
    </PageNotFoundContainer>
  );
};
