import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { PurpleButton } from "../../components/buttons/purpleButton";
import { TextBox } from "../../components/textBox";
import publicidadesService from "../../services/publicidadesService";
import { scrollUp } from "../../utils/pageUtils";
import { IPublicidades } from "./../../interfaces/model/iPublicidade";
import {
  Container,
  DivAdsSloteMobile,
  DivAdsSloteWeb,
  DivDefault,
  DivImageAds,
  DivTitle,
  HomeAdsDiv,
  LinkAndSubmit,
} from "./style";

export const PostAds = () => {
  const toast = useRef<Toast>(null);
  const [adsList, setAdsList] = useState<IPublicidades[]>([]);
  const [selectedName, setSelectedName] = useState<string>("");
  const [selectedImageWeb, setSelectedImageWeb] = useState<string>("");
  const [selectedImageMobile, setSelectedImageMobile] = useState<string>("");
  const [selectedLink, setSelectedLink] = useState<string>("");

  useEffect(() => {
    scrollUp();
    publicidadesService
      .getAllAds()
      .then((res) => {
        setAdsList(res.data);
      })
      .catch((err) => {
        toastMessage("Falha ao obter ads", "error");
      });
  }, []);

  const handleUpdateAds = () => {
    if (selectedAds) {
      const updatedAds = { ...selectedAds };

      if (selectedImageWeb) {
        updatedAds.imagemWeb = selectedImageWeb;
      }

      if (selectedImageMobile) {
        updatedAds.imagemMobile = selectedImageMobile;
      }

      if (selectedLink) {
        updatedAds.linkUrl = selectedLink;
      }
      const data = {
        nome: selectedAds.nome,
        imagemWeb:
          selectedImageWeb == "" ? selectedAds.imagemWeb : selectedImageWeb,
        recomendadoWeb: selectedAds.recomendadoWeb,
        imagemMobile:
          selectedImageMobile == ""
            ? selectedAds.imagemMobile
            : selectedImageMobile,
        recomendadoMobile: selectedAds.recomendadoMobile,
        linkUrl: selectedLink == "" ? selectedAds.linkUrl : selectedLink,
      };

      publicidadesService
        .putAds(updatedAds.id!, data) // Pass the id as the first argument
        .then((res) => {
          if (res.status === 200) {
            toastMessage("Ads atualizado com sucesso", "success");
          }
        })
        .catch((err) => toastMessage("Falha ao atualizar ads.", "error"));
    }
  };

  const selectedAds = adsList.find((ads) => ads.nome === selectedName);
  const handleImageWebChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.value;
    setSelectedImageWeb(image);
  };

  const handleImageMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.value;
    setSelectedImageMobile(image);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const name = e.target.value;
    setSelectedName(name);
    setSelectedImageWeb("");
    setSelectedImageMobile("");
  };

  function toastMessage(message: string, type: "error" | "success") {
    toast.current?.show({
      severity: type,
      summary: type === "success" ? "Sucesso" : "Erro",
      detail: message,
      life: 3000,
    });
  }

  return (
    <>
      <Toast ref={toast} />
      <Container>
        <HomeAdsDiv>
          <DivTitle>
            <h1 style={{ color: "#fff" }}>Adicionar Ads</h1>
            <select value={selectedName} onChange={handleSelectChange}>
              <option value="selecione">Selecionar</option>
              {adsList.map((ads) => (
                <option key={ads.id} value={ads.nome}>
                  {ads.nome}
                </option>
              ))}
            </select>
          </DivTitle>
          <DivImageAds>
            {selectedAds ? (
              <>
                <DivAdsSloteWeb>
                  <h2 style={{ color: "#fff" }}>Imagem Web</h2>
                  <img
                    src={selectedImageWeb || selectedAds.imagemWeb}
                    alt="Imagem Web"
                  />
                  <h4
                    style={{ color: "#fff" }}
                  >{`Recomendado: ${selectedAds.recomendadoWeb}`}</h4>
                  <input
                    value={selectedImageWeb}
                    placeholder="Qual a imagem nova?"
                    onChange={handleImageWebChange}
                  />
                </DivAdsSloteWeb>
                <DivAdsSloteMobile>
                  <h2 style={{ color: "#fff" }}>Imagem Mobile</h2>
                  <img
                    src={selectedImageMobile || selectedAds.imagemMobile}
                    alt="Imagem Mobile"
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4
                      style={{ color: "#fff" }}
                    >{`Recomendado: ${selectedAds.recomendadoMobile}`}</h4>
                    <input
                      value={selectedImageMobile}
                      placeholder="Qual a imagem nova?"
                      onChange={handleImageMobileChange}
                    />
                  </div>
                </DivAdsSloteMobile>
              </>
            ) : (
              <DivDefault>
                <p>Selecione um Ads</p>
              </DivDefault>
            )}
          </DivImageAds>
          {selectedAds && (
            <LinkAndSubmit>
              <TextBox
                title="Digite o novo link do anunciante"
                value={selectedLink}
                onChangeText={setSelectedLink}
                height="48px"
              />
              <div style={{ margin: "16px" }} />
              <PurpleButton title="Atualizar" onClick={handleUpdateAds} />
            </LinkAndSubmit>
          )}
        </HomeAdsDiv>
      </Container>
    </>
  );
};
