import styled from "styled-components";

interface Props {
  isPageNotFound: boolean;
}

export const PageNotFoundContainer = styled.div<Props>`
  display: ${(props) => (props.isPageNotFound ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  padding: 1rem;
  flex-wrap: wrap;
  height: 70vh;

  h2 {
    font-size: 1.75rem;
    color: ${(props) => props.theme["purple-500"]};
  }

  p {
    color: ${(props) => props.theme["purple-400"]};
    font-size: 0.875rem;
  }
`;
